import React, { useContext, useEffect, useState } from "react";
import { Button, Card, Col, Form, Modal, Row } from "react-bootstrap";
import { v4 as uuidv4 } from "uuid";
import {
  BsArrowLeft,
  BsArrowRight,
  BsCheck2,
  BsPencil,
  BsPlus,
  BsTrash,
  BsX,
} from "react-icons/bs";
import styled from "styled-components";
import { useValidation, feedBack, isInvalidFeedBack } from "hooks/Validation";
import { ApiInterface, BrainzAuthContext } from "@brainz_group/webappframework";
import {
  InterfaceAnswersModel,
  InterfaceQuestionsModel,
} from "interfaces/Interfaces";

import PageLoader from "components/Loader/Loader";
import BrainzComboBox from "components/BrainzComboBox/BrainzComboBox";
import Stepper from "components/Stepper";
import { TableComponent } from "@brainz_group/webappuiframework";

interface ModalNewFormProps {
  showModalNewForm: boolean;
  onHide: () => void;
  saveSuccess: () => void;
  saveSuccessUpdate: Function;
  saveError: Function;
  saveErrorUpdate: Function;
  loadPage: () => void;
  isEdit: boolean;
  itemData: any;
}

export default function ModalNewForm({
  showModalNewForm,
  onHide,
  saveSuccess,
  saveSuccessUpdate,
  saveError,
  saveErrorUpdate,
  loadPage,
  isEdit,
  itemData,
}: ModalNewFormProps) {
  const { getDecodedToken } = useContext(BrainzAuthContext);
  const institutionId = getDecodedToken()?.institutionId;
  const [
    validationForm,
    setValidationFormField,
    validationErrors,
    setNewValidationErrors,
  ] = useValidation();

  // STATES
  const [loading, setLoading] = useState(false);
  const [stepNewForm, setStepNewForm] = useState(1);

  const [nameForm, setNameForm] = useState("");
  const [descriptionForm, setDescriptionForm] = useState("");

  const [typeQuestionId, setTypeQuestionId] = useState("");
  const [typeQuestion, setTypeQuestion] = useState("");
  const [requiredAnswer, setRequiredAnswer] = useState(false);
  const [questionDescription, setQuestionDescription] = useState("");
  const [questionsList, setQuestionsList] = useState<
    Array<InterfaceQuestionsModel>
  >([]);

  const [isQuestionsResultEmpty, setIsQuestionsResultEmpty] = useState(false);

  const [isEditQuestion, setIsEditQuestion] = useState<boolean>(false);
  const [editQuestionData, setEditQuestionData] = useState({});

  const [answerList, setAnswerList] = useState<Array<InterfaceAnswersModel>>([
    {
      description: "",
      ordenation: 1,
    },
  ]);

  // FUNCTIONS
  const AddNewAnswer = () => {
    let answers = [...answerList];
    let ordenationCounter = 0;

    answers.map((element) => {
      if (ordenationCounter < element.ordenation) {
        ordenationCounter = element.ordenation;
      }
      return null;
    });

    let newItem: InterfaceAnswersModel = {
      ordenation: ordenationCounter + 1,
      description: "",
    };
    answers.push(newItem);
    setAnswerList(answers);
  };

  const removeAnswer = (index: any) => {
    const newArr = [...answerList];
    newArr?.splice(index, 1);
    setAnswerList(newArr);
  };

  const handleRemoveQuestion = (item: any) => {
    const questionIndex = questionsList?.findIndex(
      (el) => el.uniqueId === item.uniqueId
    );

    const newArr = [...questionsList];
    newArr?.splice(questionIndex, 1);
    setQuestionsList(newArr);
  };

  const handleClearEditQuestion = () => {
    handleQuestionDescriptionOnChange("");
    setAnswerList([]);

    setRequiredAnswer(false);
    setIsEditQuestion(false);
    setEditQuestionData({});
  };

  const handleEditQuestion = (item: any) => {
    handleQuestionTypeOnChange(item?.questionTypeId);
    handleQuestionDescriptionOnChange(item?.description);

    setRequiredAnswer(item.mandatory);
    setIsEditQuestion(true);
    setEditQuestionData(item);

    if (item?.answers) {
      setAnswerList(item?.answers);
    }
  };

  const handleNameOnChange = (value: string) => {
    setNameForm(value);
    setValidationFormField("nameForm", value);
  };

  const handleDescriptionOnChange = (value: string) => {
    setDescriptionForm(value);
    setValidationFormField("descriptionForm", value);
  };

  const handleQuestionDescriptionOnChange = (value: string) => {
    setQuestionDescription(value);
    setValidationFormField("questionDescription", value);
  };

  const handlerNextStep = () => {
    let errors = findErrors();
    let errorExist = setNewValidationErrors(errors);

    if (!errorExist) {
      setStepNewForm(2);
    }
  };

  const findErrors = () => {
    let result: any = {};

    if (validationForm.current) {
      if (
        !validationForm.current.nameForm ||
        validationForm.current.nameForm === ""
      )
        result.nameForm = "Campo obrigatório";
      else result.nameForm = undefined;
      if (
        !validationForm.current.descriptionForm ||
        validationForm.current.descriptionForm === ""
      )
        result.descriptionForm = "Campo obrigatório";
      else result.descriptionForm = undefined;
    }
    return result;
  };

  const handleSaveNewForm = () => {
    if (questionsList?.length <= 0) return;

    let payload = {
      institutionId: institutionId,
      title: nameForm,
      description: descriptionForm,
      questions: questionsList,
    };

    if (isEdit) {
      setLoading(true);
      ApiInterface.put(`/Evaluation/${itemData?.id}/Update`, payload)
        .then((response: any) => {
          setLoading(false);
          if (response.status === 200) {
            saveSuccessUpdate();
            onHide();
            loadPage();
          } else {
            const errors = response?.response?.data?.errors;

            onHide();

            if (errors) {
              if (errors?.some((err: any) => err.code === "EE004")) {
                saveErrorUpdate(
                  true,
                  "Já existe um formulário cadastrado com este nome."
                );
              } else {
                saveErrorUpdate(true, errors[0]?.message);
              }
            } else {
              saveErrorUpdate(true, "Erro ao atualizar formulário.");
            }
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log("Erro ao tentar atualizar formulário. Erro => ", error);
          saveErrorUpdate(true, "Erro ao atualizar formulário.");
          onHide();
        });

      return;
    }

    setLoading(true);

    ApiInterface.post("/Evaluation/Insert", payload)
      .then((response: any) => {
        setLoading(false);
        if (response.status === 200) {
          saveSuccess();
          onHide();
          loadPage();
        } else {
          const errors = response?.response?.data?.errors;

          onHide();

          if (errors) {
            if (errors?.some((err: any) => err.code === "EE004")) {
              saveError(
                true,
                "Já existe um formulário cadastrado com este nome."
              );
            } else {
              saveError(true, errors[0]?.message);
            }
          } else {
            saveError(true, "Erro ao cadastrar novo formulário.");
          }
        }
      })
      .catch((error) => {
        setLoading(false);
        onHide();
        saveError(true, "Erro ao cadastrar novo formulário.");
      });
  };

  const handleQuestionTypeOnChange = (value: any, resetAnswers?: boolean) => {
    setTypeQuestionId(value);
    setValidationFormField("typeQuestionId", value);

    setLoading(true);
    ApiInterface.get(`/QuestionType/${value}/GetById`)
      .then((response) => {
        if (response.status === 200) setTypeQuestion(response.data.result.type);
        if (resetAnswers || response.data.result.type === "dissertative")
          setAnswerList([]);
        setLoading(false);
      })
      .catch((error) => {
        console.log("Erro ao tentar buscar tipos de questões. Erro => ", error);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (isEdit) {
      handleGetDataToEdit();
    }
  }, [isEdit]);

  const handleGetDataToEdit = () => {
    setLoading(true);

    ApiInterface.get(`/Evaluation/${itemData?.id}/GetById`)
      .then((response: any) => {
        handleNameOnChange(response?.data?.result?.title);
        handleDescriptionOnChange(response?.data?.result?.description);
        const arrQuestions = response?.data?.result?.questions;

        const newArr = arrQuestions?.map((question: any) => ({
          ...question,
          uniqueId: uuidv4(),
        }));

        setQuestionsList(newArr);

        setLoading(false);
      })
      .catch((error: any) => {
        setLoading(false);
        console.log(
          "Erro ao tentar buscar dados de um formulário. Erro => ",
          error
        );
      });
  };

  const handleAddOrEditQuestion = (isAdd: boolean, item?: any) => {
    let questions = [...questionsList];
    let ordenationCounterQuestions = 0;

    questions?.map((element) => {
      if (ordenationCounterQuestions < element.ordenation) {
        ordenationCounterQuestions = element.ordenation;
      }
      return null;
    });

    if (typeQuestion === "dissertative") {
      let newQuestion: InterfaceQuestionsModel = {
        ...item,
        uniqueId: isAdd ? uuidv4() : item?.uniqueId,
        uniqueTypeQuestion: typeQuestion,
        questionTypeId: typeQuestionId,
        description: questionDescription,

        ordenation: isAdd ? ordenationCounterQuestions + 1 : item.ordenation,
        mandatory: requiredAnswer,
        answers: [{
          ordenation: 1,
          description:"Descreva"
        }],
      };

      if (isAdd) {
        questions.push(newQuestion);
      } else {
        //Substituir o elemento que já existe por um novo.
        const elementIndex = questions?.findIndex(
          (el) => el.uniqueId === item?.uniqueId
        );
        questions.splice(elementIndex, 1, newQuestion);
      }

      setQuestionsList(questions);
    } else {
      let newQuestion: any = {
        ...item,
        uniqueId: isAdd ? uuidv4() : item?.uniqueId,
        uniqueTypeQuestion: typeQuestion,
        questionTypeId: typeQuestionId,
        description: questionDescription,
        ordenation: isAdd ? ordenationCounterQuestions + 1 : item.ordenation,

        mandatory: requiredAnswer,
        answers: answerList?.map((item: any) => {
          return {
            ...item,
            description: item.description,
            ordenation: item.ordenation,
          };
        }),
      };

      if (isAdd) {
        questions.push(newQuestion);
      } else {
        const elementIndex = questions.findIndex(
          (el) => el.uniqueId === item?.uniqueId
        );
        questions.splice(elementIndex, 1, newQuestion);
      }

      setQuestionsList(questions);
    }

    setTypeQuestionId("");
    setTypeQuestion("");
    setRequiredAnswer(false);
    setQuestionDescription("");
    setAnswerList([]);
    setIsEditQuestion(false);
  };

  const handleOptionAnswer = (index: any, description: string) => {
    let answers = [...answerList];
    let item = answers[index];
    item.description = description;
    answers[index] = item;
    setAnswerList(answers);
  };

  const isButtonDisabled: boolean =
    questionDescription === "" ||
    (answerList?.length > 0 &&
      answerList?.some((el) => el.description === "")) ||
    (typeQuestion !== "dissertative" && answerList?.length === 0)
      ? true
      : false;

  const handleResultIsEmpty = () => {
    setIsQuestionsResultEmpty(true);
  };

  // Side Effects
  useEffect(() => {
    setNewValidationErrors({});
  }, []);

  useEffect(() => {
    const updateValidation = () => {
      validationForm.current = {
        nameForm: nameForm,
        descriptionForm: descriptionForm,
        questionDescription: questionDescription,
      };
    };
    updateValidation();
  }, [validationForm]);

  return (
    <ModalNewFormStyles
      show={showModalNewForm}
      onHide={() => {
        onHide();
      }}
    >
      <Modal.Header>
        <Modal.Title>
          {stepNewForm === 3 ? (
            <h2> Pré-visualização do formulário de avaliação</h2>
          ) : (
            <h2>
              {isEdit ? "Editar formulário" : "Adicionar novo formulário"}
            </h2>
          )}
        </Modal.Title>
        <Button
          className="cursor-pointer"
          variant="white"
          onClick={() => {
            onHide();
          }}
        >
          <BsX color="var(--bs-modal-color)" size={24} />
        </Button>
      </Modal.Header>
      <Modal.Body className="mb-2">
        {loading && <PageLoader />}
        {stepNewForm === 1 && (
          <Form>
            <Form.Group>
              <Form.Label column sm={12}>
                Nome do formulário:
              </Form.Label>
              <Form.Control
                placeholder="Digite o nome do formulário"
                size="sm"
                type="text"
                value={nameForm}
                isInvalid={isInvalidFeedBack("nameForm", validationErrors)}
                onChange={(e) => {
                  handleNameOnChange(e.target.value);
                }}
              />
              <Form.Control.Feedback type="invalid">
                {feedBack("nameForm", validationErrors)}
              </Form.Control.Feedback>
            </Form.Group>
            <br />
            <Form.Group>
              <Form.Label column sm={12}>
                Descrição do formulário:
              </Form.Label>
              <Form.Control
                placeholder="Digite uma breve descrição sobre o formulário"
                size="sm"
                type="text"
                value={descriptionForm}
                isInvalid={isInvalidFeedBack(
                  "descriptionForm",
                  validationErrors
                )}
                onChange={(e) => {
                  handleDescriptionOnChange(e.target.value);
                }}
              />
              <Form.Control.Feedback type="invalid">
                {feedBack("descriptionForm", validationErrors)}
              </Form.Control.Feedback>
            </Form.Group>
          </Form>
        )}
        {stepNewForm === 2 &&
          (!isQuestionsResultEmpty ? (
            <>
              <Form className="questions-form">
                <section className="firstGroupContainer">
                  <div className="selectTypeQuestionContainer">
                    <Form.Label column sm={12}>
                      Selecione o tipo da pergunta:
                    </Form.Label>

                    <BrainzComboBox
                      setValue={(val) => handleQuestionTypeOnChange(val, false)}
                      value={typeQuestionId}
                      endpoint={`/QuestionType/List`}
                      idField="id"
                      valueField="description"
                      placeholder="Selecione o tipo de pergunta"
                      isInvalid={isInvalidFeedBack(
                        "typeQuestionId",
                        validationErrors
                      )}
                      handleResultIsEmpty={handleResultIsEmpty}
                    />

                    <Form.Control.Feedback type="invalid">
                      {feedBack("typeQuestionId", validationErrors)}
                    </Form.Control.Feedback>
                  </div>

                  <div className="isRequiredContainer">
                    <Form.Label column sm={12}>
                      Esta pergunta é obrigatória?
                    </Form.Label>
                    <Col sm={12}>
                      {requiredAnswer === false ? (
                        <div className="cursor-pointer ">
                          Não{" "}
                          <svg
                            onClick={() => setRequiredAnswer(true)}
                            width="60"
                            height="40"
                            viewBox="0 0 80 40"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <circle cx="20" cy="20" r="16" fill="#6F6F6F" />
                            <rect
                              x="0.5"
                              y="0.5"
                              width="79"
                              height="39"
                              rx="19.5"
                              stroke="#6F6F6F"
                            />
                          </svg>
                        </div>
                      ) : (
                        <div className="cursor-pointer">
                          Sim{" "}
                          <svg
                            onClick={() => setRequiredAnswer(false)}
                            width="60"
                            height="40"
                            viewBox="0 0 80 40"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <rect
                              width="80"
                              height="40"
                              rx="20"
                              fill="#2155BA"
                            />
                            <circle cx="60" cy="20" r="16" fill="white" />
                          </svg>
                        </div>
                      )}
                    </Col>
                  </div>
                </section>
                {loading ? (
                  <PageLoader />
                ) : typeQuestion === "" ? (
                  ""
                ) : (
                  <>
                    {typeQuestion === "dissertative" && (
                      <Form.Group as={Row} className="my-2">
                        <Col md={12}>
                          <Form.Label column sm={12}>
                            Descreva a pergunta abaixo:
                          </Form.Label>
                          <Col sm={12} style={{ width: "98%" }}>
                            <Form.Control
                              placeholder="Digite a pergunta"
                              size="sm"
                              type="text"
                              value={questionDescription}
                              onChange={(e) => {
                                handleQuestionDescriptionOnChange(
                                  e.target.value
                                );
                              }}
                              isInvalid={isInvalidFeedBack(
                                "questionDescription",
                                validationErrors
                              )}
                            />
                            <Form.Control.Feedback type="invalid">
                              {feedBack(
                                "questionDescription",
                                validationErrors
                              )}
                            </Form.Control.Feedback>
                          </Col>
                        </Col>
                      </Form.Group>
                    )}

                    {typeQuestion === "unique" ||
                    typeQuestion === "multiple" ? (
                      <>
                        <Form.Group as={Row} className="my-2">
                          <Col md={12}>
                            <Form.Label column sm={12}>
                              Descreva a pergunta abaixo:
                            </Form.Label>
                            <Col sm={12} style={{ width: "98%" }}>
                              <Form.Control
                                placeholder="Digite a pergunta"
                                value={questionDescription}
                                size="sm"
                                type="text"
                                onChange={(e) => {
                                  handleQuestionDescriptionOnChange(
                                    e.target.value
                                  );
                                }}
                                isInvalid={isInvalidFeedBack(
                                  "questionDescription",
                                  validationErrors
                                )}
                              ></Form.Control>
                              <Form.Control.Feedback type="invalid">
                                {feedBack(
                                  "questionDescription",
                                  validationErrors
                                )}
                              </Form.Control.Feedback>
                            </Col>
                          </Col>
                        </Form.Group>
                        <Form.Label column sm={12}>
                          Descreva as alternativas abaixo:
                        </Form.Label>

                        {answerList &&
                          answerList?.map((item, index) => {
                            return (
                              <Form.Group as={Row} className="mb-2">
                                <Col sm={11}>
                                  <Form.Control
                                    placeholder="Digite o nome da alternativa"
                                    size="sm"
                                    type="text"
                                    value={item.description}
                                    onChange={(e) => {
                                      handleOptionAnswer(index, e.target.value);
                                    }}
                                  />
                                </Col>
                                <Col
                                  className="cursor-pointer"
                                  style={{ alignSelf: "center" }}
                                  onClick={() => removeAnswer(index)}
                                >
                                  <BsX
                                    color="var(--bs-modal-color)"
                                    size={24}
                                  />
                                </Col>
                              </Form.Group>
                            );
                          })}

                        <Form.Group as={Row} className="mb-2">
                          <Col sm={12}>
                            <Button
                              variant=""
                              className="sucess-button-add-new-answer "
                              style={{ width: 100 + "%" }}
                              onClick={AddNewAnswer}
                            >
                              <BsPlus color="#Fff" size={24} />
                              Adicionar nova alternativa
                            </Button>
                          </Col>
                        </Form.Group>
                      </>
                    ) : (
                      ""
                    )}
                    {typeQuestion === "" ? (
                      ""
                    ) : (
                      <Form.Group as={Row} className="addUpdateButtonsFooter">
                        <Col sm={12} className="addUpdateButtonsFooterCol">
                          <Button
                            variant="primary"
                            className="sucess-button button-question"
                            disabled={isButtonDisabled}
                            onClick={() => {
                              if (
                                answerList?.some((el) => el.description === "")
                              ) {
                                return;
                              }
                              isEditQuestion
                                ? handleAddOrEditQuestion(
                                    false,
                                    editQuestionData
                                  )
                                : handleAddOrEditQuestion(true);
                            }}
                          >
                            {isEditQuestion
                              ? "Editar pergunta"
                              : "Adicionar pergunta"}
                            <BsCheck2 className="ml-3" color="#Fff" size="24" />
                          </Button>

                          {isEditQuestion && (
                            <Button
                              variant="primary"
                              className="cancel-button button-question"
                              onClick={handleClearEditQuestion}
                            >
                              Cancelar edição
                              <BsX className="mr-2" color="#A56300" size={24} />
                            </Button>
                          )}
                        </Col>
                      </Form.Group>
                    )}
                  </>
                )}
              </Form>

              {questionsList?.length > 0 && (
                <section className="questions-list">
                  <>
                    <h5>Perguntas Adicionadas</h5>
                    <TableComponent
                      data={questionsList}
                      columns={[
                        {
                          headerName: "Ordem da Pergunta",
                          property: "ordenation",
                          type: "text",
                          headerClass: "border-radius-left",
                        },
                        {
                          headerName: "Descrição da Pergunta",
                          property: "description",
                          type: "text",
                          headerClass: "",
                        },

                        {
                          headerName: "Ações",
                          property: "",
                          type: "actions",
                          headerClass: "border-radius-right",
                          actions: [
                            {
                              nameIcon: "BsPencil",
                              callback: (item: any) => handleEditQuestion(item),
                              className: "cursor-pointer action-item",
                              color: "#0F7EAA",
                              size: 22,
                            },
                            {
                              nameIcon: "BsTrash",
                              callback: (item: any) =>
                                handleRemoveQuestion(item),
                              className: "cursor-pointer action-item",
                              color: "#A56300",
                              size: 22,
                            },
                          ],
                        },
                      ]}
                    />
                  </>
                </section>
              )}
            </>
          ) : (
            <div className="errorQuestionsResultEmptyContainer">
              <span>
                Não existe nenhum tipo de pergunta cadastrado.
                <br />
                <br />
                Cadastre pelo menos um tipo de pergunta e tente novamente.
              </span>
            </div>
          ))}
        {stepNewForm === 3 && (
          <Form>
            <Row className="mt-4 mb-2">
              <Col>
                <h2>
                  Nome do formulário: <strong>{nameForm}</strong>
                </h2>
              </Col>
            </Row>

            {questionsList?.length > 0 ? (
              <>
                <Form.Group as={Row} className="">
                  <Col md={12}>
                    <Form.Label column sm={12}>
                      <span
                        style={{
                          fontSize: "20px",
                          lineHeight: "30px",
                          fontWeight: 600,
                          color: "#3c3b3b",
                        }}
                      >
                        Perguntas:
                      </span>
                    </Form.Label>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="">
                  {questionsList?.map((item, index) => {
                    return (
                      <>
                        <Row className="py-1">
                          <Col>
                            <Card className="CardPreview">
                              <Card.Title className="CardTitle">
                                <h2>{`${item?.ordenation}) ${item?.description}`}</h2>

                                <div className="previewButtonsForm">
                                  <button
                                    onClick={(e) => {
                                      e.preventDefault();
                                      handleEditQuestion(item);
                                      setStepNewForm(2);
                                    }}
                                  >
                                    <BsPencil
                                      className="ml-3"
                                      color="#0F7EAA"
                                      size="22"
                                    />
                                  </button>
                                  <button
                                    onClick={(e) => {
                                      e.preventDefault();
                                      handleRemoveQuestion(item);
                                    }}
                                  >
                                    <BsTrash
                                      className="ml-3"
                                      color="#A56300"
                                      size="22"
                                    />
                                  </button>
                                </div>
                              </Card.Title>
                              <Card.Body>
                                {!item.answers && (
                                  <textarea className="textarea " disabled />
                                )}
                                {item.answers &&
                                  item.answers.map((x) => {
                                    return (
                                      <>
                                        <Row>
                                          <Col md={1}>
                                            <input
                                              disabled
                                              type={
                                                item?.uniqueTypeQuestion ===
                                                "unique"
                                                  ? "radio"
                                                  : "checkbox"
                                              }
                                              className="checkbox"
                                            />
                                          </Col>
                                          <Col md={10}>{x.description}</Col>
                                        </Row>
                                      </>
                                    );
                                  })}
                                <Col md={12} style={{ textAlign: "right" }}>
                                  {item.mandatory ? (
                                    <strong>
                                      <h2>*Obrigatório</h2>
                                    </strong>
                                  ) : (
                                    ""
                                  )}
                                </Col>
                              </Card.Body>
                            </Card>
                          </Col>
                        </Row>
                      </>
                    );
                  })}
                </Form.Group>
              </>
            ) : (
              <span
                style={{
                  fontSize: "16px",
                  lineHeight: "20px",
                  fontWeight: 600,
                  color: "#3c3b3b",
                  margin: "1rem 0",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <br />
                Nenhuma pergunta adicionada.
                <br />
                <br />
                Adicione ao menos uma pergunta para continuar.
                <br />
                <br />
                <Button
                  variant="primary"
                  className="cancel-button"
                  style={{
                    display: "flex",
                    alignSelf: "center",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    setStepNewForm(2);
                  }}
                >
                  <BsArrowLeft color="#A56300" size="24" />
                  Adicionar perguntas
                </Button>
              </span>
            )}
          </Form>
        )}
      </Modal.Body>
      <Modal.Footer>
        {stepNewForm === 1 && (
          <>
            {" "}
            <Button
              variant="primary"
              className="cancel-button"
              onClick={() => onHide()}
            >
              <BsX className=" mr-2" color="#A56300" size={24} />
              Descartar formulário
            </Button>
            <Stepper totalSteps={3} currentStep={1} />
            <Button
              variant="primary"
              className="sucess-button"
              disabled={!nameForm || !descriptionForm}
              onClick={() => handlerNextStep()}
            >
              Continuar
              <BsArrowRight className="ml-3" color="#Fff" size="24" />
            </Button>
          </>
        )}
        {stepNewForm === 2 && (
          <>
            {" "}
            <Button
              variant="primary"
              className="cancel-button"
              onClick={() => setStepNewForm(1)}
            >
              <BsArrowLeft color="#A56300" size="24" />
              Voltar
            </Button>
            <Stepper totalSteps={3} currentStep={2} />
            <Button
              variant="primary"
              className="sucess-button"
              disabled={questionsList?.length <= 0 || isEditQuestion}
              onClick={() => setStepNewForm(3)}
            >
              Visualizar formulário
              <BsArrowRight color="#Fff" size="24" className="mx-1" />
            </Button>
          </>
        )}
        {stepNewForm === 3 && (
          <>
            {" "}
            <Button
              variant="primary"
              className="cancel-button"
              onClick={() => setStepNewForm(2)}
            >
              <BsArrowLeft color="#A56300" size="24" />
              Voltar
            </Button>
            <Stepper totalSteps={3} currentStep={3} />
            <Button
              variant="primary"
              className="sucess-button"
              disabled={questionsList?.length <= 0}
              onClick={() => handleSaveNewForm()}
            >
              Salvar formulário
              <BsCheck2 color="#Fff" size="24" />
            </Button>
          </>
        )}
      </Modal.Footer>
    </ModalNewFormStyles>
  );
}

const ModalNewFormStyles = styled(Modal)`
  .CardPreview {
    background-color: #d7e4f8;
    width: 100%;
    padding: 15px 5px 5px 15px;
    .textarea {
      width: 100%;
    }
    .checkbox {
      width: 20px;
      height: 20px;
    }

    .CardTitle {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .previewButtonsForm {
        display: flex;
        justify-content: flex-end;
        gap: 10px;
        margin-right: 1rem;

        button {
          border: unset;
          outline: unset;
          box-shadow: unset;
          background: unset;
        }
      }
    }
  }
  .modal-dialog {
    margin: 0px auto;
    overflow-y: initial !important;
    height: 100% !important;
  }
  .modal-content {
    width: 800px;
    height: 100%;
  }

  .modal-body {
    overflow-y: auto !important;
    overflow-x: clip !important;
    max-height: calc(100vh-100px);
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    overflow-y: auto;
    overflow-x: clip;
    margin: 0;
    padding-left: 25px !important;
    padding-right: 25px !important;

    padding: 0px;

    .questions-form {
      margin-bottom: 1rem;
      padding-bottom: 1rem;
      border-bottom: 1px solid #d4d4d4;

      .firstGroupContainer {
        padding-top: 10px;
        display: flex;
        align-items: center;
        gap: 1rem;

        .selectTypeQuestionContainer {
          width: 70%;

          .select-search {
            width: 95%;
          }
        }

        .isRequiredContainer {
          width: 30%;

          .cursor-pointer {
            display: flex;
            align-items: center;
            gap: 10px;
          }
        }
      }
    }

    .questions-list {
      height: 100px;
      h5 {
        color: #777777;
      }

      .table-responsive {
        td {
          width: 0 !important;
        }
        .action-item {
          margin: 0 8px;
        }
      }
    }

    .addUpdateButtonsFooterCol {
      display: flex;
      align-items: center;
      gap: 1rem;
      margin: 0;

      border-top: 0.5px solid #d4d4d4;
    }
  }
  .form-control {
    padding: 2px 16px 0px;
    width: 100%;
    height: 48px;
    left: 24px;
    top: 104px;
    background: #ffffff;
    border: 1px solid #a6a6a6;
    box-sizing: border-box;
  }
  .form-select-type {
    padding: 10px;
    width: 100%;
    height: 48px;
    left: 24px;
    top: 104px;
    background: #ffffff;
    border: 1px solid #a6a6a6;
    box-sizing: border-box;
    border-radius: 8px;
  }
  h2 {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #6f6f6f;
  }

  .modal-footer {
    border-top: none;
    place-content: space-between;

    button {
      display: flex !important;
      gap: 8px !important;
      align-items: center !important;
      justify-content: center !important;

      & svg {
        margin: 0 !important;
      }
    }
  }

  .cancel-button {
    width: 196px;
    height: 48px;
    color: #a56300;
    background-color: #fff !important;
    border: 1px solid #a56300 !important;
    box-sizing: border-box;
    border-radius: 8px;
    &:hover {
      color: #fff !important;
      background-color: #a56300 !important;
      svg {
        fill: #fff !important;
      }
    }
    &:active {
      color: #fff !important;
      background-color: #a56300 !important;
      .icon-close {
        fill: #fff !important;
      }
    }
    &:focus {
      border: none !important;
    }
  }
  .sucess-button {
    width: 190px;
    height: 48px;
    background: #008555;
    border: 1px solid #008555;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    &:hover {
      color: #fff !important;
      background-color: #153d89 !important;
      border: 1px solid #153d89;
      .icon-close {
        fill: #fff !important;
      }
    }
    &:active {
      color: #fff !important;
      background-color: #153d89 !important;
      .icon-close {
        fill: #fff !important;
      }
    }
    &:focus {
      border: none !important;
    }
  }

  .danger-button {
    width: 190px;
    height: 48px;
    background: #850000 !important;
    border: 1px solid #850000 !important;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    &:hover {
      color: #fff !important;
      background-color: #850000 !important;
      border: 1px solid #850000;
      .icon-close {
        fill: #fff !important;
      }
    }
    &:active {
      color: #fff !important;
      background-color: #850000 !important;
      .icon-close {
        fill: #fff !important;
      }
    }
    &:focus {
      border: none !important;
    }
  }

  .sucess-button-add-new-answer {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
    width: 48% !important;
    height: 48px;
    margin: 1rem auto 0;
    border-radius: 8px;
    margin: 12px 0;

    color: #153d89 !important;
    border: 1px solid #153d89 !important;
    background: #fff;

    &:hover {
      color: #fff !important;
      background-color: #153d89 !important;
      border: 1px solid #153d89;

      .icon-close {
        fill: #153d89 !important;
        width: 20px;
      }
    }
    &:active {
      background-color: #153d89 !important;
      .icon-close {
        fill: #153d89 !important;
      }
    }
    &:focus {
      border: none !important;
    }
  }
  .button-radio {
    margin-right: 10px;
    font-size: 16px;
    line-height: 35px;
    color: #6f6f6f;
    input {
      width: 32px !important;
      height: 32px !important;
    }
    label {
      padding-left: 20px;
      padding-top: 8px;
    }
  }

  .button-question {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;

    margin-top: 1rem;

    width: 48%;
  }

  .errorQuestionsResultEmptyContainer {
    padding: 2rem 0;
    display: flex;

    span {
      font-size: 20px;
      line-height: 30px;
      color: #e42c2c;
      text-align: center;
    }
  }
`;
