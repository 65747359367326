import styled from "styled-components";
import { Col, Modal, Row, Form, Button, Table } from "react-bootstrap";
import PageLoader from "components/Loader/Loader";
import { useContext, useEffect, useState } from "react";
import {
  BsArrowLeft,
  BsArrowRight,
  BsX,
  BsXCircle,
  BsCheck2,
} from "react-icons/bs";
import Stepper from "components/Stepper";
import Search from "components/Input/Search";
import { ApiInterface, BrainzAuthContext } from "@brainz_group/webappframework";
import { TableComponent } from "@brainz_group/webappuiframework";
import { useValidation, feedBack, isInvalidFeedBack } from "hooks/Validation";
import {
  InterfaceGroupModel,
  InterfaceOfficeGroups,
} from "interfaces/Interfaces";
import SelectedItems from "components/SelectedItems";
interface ModalNewGroupProps {
  show: boolean;
  onHide: () => void;
  saveSuccess: () => void;
  saveSuccessUpdate: () => void;
  saveError: Function;
  loadPage: () => void;
  isEdit?: boolean;
  itemId?: string;
}

export default function ModalNewGroup(props: ModalNewGroupProps) {
  const { getDecodedToken } = useContext(BrainzAuthContext);
  const institutionId = getDecodedToken()?.institutionId;
  const [
    validationForm,
    setValidationFormField,
    validationErrors,
    setNewValidationErrors,
  ] = useValidation();
  const [stepNewGroup, setStepNewGroup] = useState(1);
  const [searchFilter, setSearchFilter] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const [officeTeams, setOfficeTeams] = useState<any>([]);
  const [nameGroup, setNameGroup] = useState("");
  const [descriptionGroup, setDescriptionGroup] = useState("");
  const [officeTeamsSelected, setOfficeTeamsSelected] = useState<any>([]);
  const { isEdit } = props;
  const [showSelectedItems, setShowSelectedItems] = useState<boolean>(
    isEdit ? true : false
  );

  function queryFilters() {
    let query = "";
    if (searchFilter !== "") query = query += "?search=" + searchFilter;
    return query;
  }

  const handleLoadUpdateInfos = () => {
    setLoading(true);
    if (props.itemId) {
      ApiInterface.get(`/Group/${props.itemId}/GetById`)
        .then((response: any) => {
          handleNameOnChange(response.data.result.name);
          handleDescriptionOnChange(response.data.result.description);
          setOfficeTeamsSelected(response.data.result.groupsOffice);
          setLoading(false);
        })
        .catch((error: any) => {
          console.error(
            "Erro ao tentar pegar um grupo por ID. Erro => ",
            error
          );
          setLoading(false);
        });
    }
  };

  const handleLoadTeamsInfos = () => {
    setLoading(true);

    ApiInterface.get(`/Group/${institutionId}/ListOffice${queryFilters()}`)
      .then((response) => {
        setShowSelectedItems(false);
        setOfficeTeams(response.data.result);
        setLoading(false);
      })
      .catch((error: any) => {
        setLoading(false);
        console.error(
          error,
          "Erro ao tentar pegar as turmas de um grupo. Erro => ",
          error
        );
      });
  };

  useEffect(() => {
    if (searchFilter !== "") {
      handleLoadTeamsInfos();
    }
  }, [searchFilter]);

  useEffect(() => {
    if (isEdit) {
      handleLoadUpdateInfos();
    }
  }, [isEdit]);

  function selectOfficeGroup(item: InterfaceOfficeGroups, isCheked: boolean) {
    if (isCheked === true) {
      //Pego estado atual de officeTeamsSelected e acrescendo essa item(turmaOffice)
      let newGroup = [...officeTeamsSelected];
      newGroup.push(item);
      setOfficeTeamsSelected(newGroup);
    } else {
      //Pego estado atual de officeTeamsSelected e retiro esse item(turmaOffice)
      let newGroup = [...officeTeamsSelected];
      newGroup = newGroup.filter((x) => x.officeId !== item.officeId);
      setOfficeTeamsSelected(newGroup);
    }
  }

  const selectItemOffice = (item: any) => {
    if (
      officeTeamsSelected?.some(
        (selected: any) => selected.officeId === item.officeId
      )
    ) {
      setOfficeTeamsSelected(
        officeTeamsSelected?.filter(
          (selected: any) => selected?.officeId !== item?.officeId
        )
      );
    } else {
      setOfficeTeamsSelected([...officeTeamsSelected, item]);
    }
  };

  const selectAllItemsOffice = () => {
    if (officeTeamsSelected.length === officeTeams.length) {
      setOfficeTeamsSelected([]);
    } else {
      setOfficeTeamsSelected([...officeTeams]);
    }
  };

  const isItemOfficeSelected = (item: any) => {
    if (
      officeTeamsSelected?.some(
        (element: any) => element?.officeId === item?.officeId
      )
    ) {
      return true;
    }
    return false;
  };

  const areAllItemsOfficeSelected =
    officeTeamsSelected?.length === officeTeams?.length;

  function handleRemoveTeam(item: any) {
    let newGroup = [...officeTeamsSelected];
    newGroup = newGroup.filter((x) => x.officeId !== item.officeId);
    setOfficeTeamsSelected(newGroup);
  }
  function ClearFilters() {
    setSearchFilter("");
    setShowSelectedItems(true);
  }
  function handleNameOnChange(value: string) {
    setNameGroup(value);
    setValidationFormField("nameGroup", value);
  }
  function handleDescriptionOnChange(value: string) {
    setDescriptionGroup(value);
    setValidationFormField("descriptionGroup", value);
  }
  useEffect(() => {
    setNewValidationErrors({});
  }, []);

  useEffect(() => {
    updateValidation();
    function updateValidation() {
      validationForm.current = {
        nameGroup: nameGroup,
        description: descriptionGroup,
      };
    }
  }, [validationForm]);

  function handlerNextStep() {
    let errors = findErrors();
    let errorExist = setNewValidationErrors(errors);

    if (!errorExist) {
      setStepNewGroup(2);
    }
  }
  function findErrors() {
    let result: any = {};

    if (validationForm.current) {
      if (
        !validationForm.current.nameGroup ||
        validationForm.current.nameGroup === ""
      )
        result.nameGroup = "Campo obrigatório";
      else result.nameGroup = undefined;
      if (
        !validationForm.current.descriptionGroup ||
        validationForm.current.descriptionGroup === ""
      )
        result.descriptionGroup = "Campo obrigatório";
      else result.descriptionGroup = undefined;
    }

    return result;
  }

  function handleSaveNewGroup() {
    if (selectOfficeGroup?.length <= 0) return <></>;

    setLoading(true);

    let payload: InterfaceGroupModel = {
      name: nameGroup,
      description: descriptionGroup,
      institutionId: institutionId,
      groupsOffice: officeTeamsSelected,
    };

    if (isEdit) {
      ApiInterface.put(`/Group/${props.itemId}/Update`, payload)
        .then((response: any) => {
          if (response.status === 200) {
            props.saveSuccessUpdate();
            props.onHide();
            props.loadPage();
          } else {
            const errors = response?.response?.data?.errors;
            props.onHide();

            if (errors) {
              if (errors?.some((err: any) => err.code === "EE004")) {
                props.saveError(
                  true,
                  "Já existe um grupo cadastrado com este nome."
                );
              } else {
                props.saveError(true, errors[0]?.message);
              }
            } else {
              props.saveError(true, "Erro ao atualizar grupo.");
            }
          }
        })
        .catch((error) => {
          console.log(
            "Erro ao tentar atualizar dados de um grupo. Erro => ",
            error
          );
          props.onHide();
          props.saveError(true, "Erro ao atualizar grupo.");
        });

      return;
    }

    ApiInterface.post("/Group/Insert", payload)
      .then((response: any) => {
        if (response.status === 200) {
          props.saveSuccess();
          props.onHide();
          props.loadPage();
        } else {
          const errors = response?.response?.data?.errors;
          props.onHide();

          if (errors) {
            if (errors?.some((err: any) => err.code === "EE004")) {
              props.saveError(
                true,
                "Já existe um grupo cadastrado com este nome."
              );
            } else {
              props.saveError(true, errors[0]?.message);
            }
          } else {
            props.saveError(true, "Erro ao cadastrar grupo.");
          }
        }

        setLoading(false);
      })
      .catch((error) => {
        console.error("Erro ao tentar cadastrar novo grupo. Erro => ", error);
        props.onHide();
        props.saveError(true, "Erro ao cadastrar grupo.");
        setLoading(false);
      });
  }

  return (
    <ModalNewGroupStyles
      show={props.show}
      onHide={() => {
        props.onHide();
      }}
    >
      <Modal.Header>
        <Modal.Title>
          <h2>{isEdit ? "Editar grupo" : " Criar novo grupo"}</h2>
        </Modal.Title>
        <Button
          className="cursor-pointer"
          variant="white"
          onClick={() => {
            props.onHide();
          }}
        >
          <BsX size={24} />
        </Button>
      </Modal.Header>
      <Modal.Body className="mb-2">
        {stepNewGroup === 1 && (
          <Form>
            <Form.Group as={Row}>
              <Form.Label column sm={12}>
                Nome:
              </Form.Label>
              <Col sm={12}>
                <Form.Control
                  placeholder="Digite o nome do grupo"
                  size="sm"
                  type="text"
                  value={nameGroup}
                  isInvalid={isInvalidFeedBack("nameGroup", validationErrors)}
                  onChange={(e) => {
                    handleNameOnChange(e.target.value);
                  }}
                ></Form.Control>
                <Form.Control.Feedback type="invalid">
                  {feedBack("nameGroup", validationErrors)}
                </Form.Control.Feedback>
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm={12}>
                Descrição do grupo:
              </Form.Label>
              <Col sm={12}>
                <Form.Control
                  placeholder="Digite a descrição do grupo"
                  size="sm"
                  type="text"
                  value={descriptionGroup}
                  isInvalid={isInvalidFeedBack(
                    "descriptionGroup",
                    validationErrors
                  )}
                  onChange={(e) => {
                    handleDescriptionOnChange(e.target.value);
                  }}
                ></Form.Control>
                <Form.Control.Feedback type="invalid">
                  {feedBack("descriptionGroup", validationErrors)}
                </Form.Control.Feedback>
              </Col>
            </Form.Group>
          </Form>
        )}
        {stepNewGroup === 2 && (
          <Form>
            <Form.Group as={Row}>
              <Form.Label column sm={12}>
                Pesquise e selecione as turmas desejadas:
              </Form.Label>
              <Col sm={searchFilter === "" ? 12 : 10} className="mr-0">
                <Search
                  setSearchFilter={setSearchFilter}
                  searchFilter={searchFilter}
                  placeholder="Buscar por nome da turma"
                />
              </Col>
              <Col md={1} className="px-0 mx-0">
                {searchFilter && (
                  <Button
                    className="clear-filter mx-1"
                    onClick={ClearFilters}
                    title="Limpar filtro"
                  >
                    <BsXCircle
                      className="bi bi-x-circle"
                      color="#A56300"
                      size={24}
                    />
                  </Button>
                )}
              </Col>
            </Form.Group>

            {loading ? (
              <PageLoader />
            ) : officeTeams && officeTeams.length > 0 ? (
              searchFilter !== "" ? (
                <>
                  <Table striped hover className="TableComponentStyles">
                    <thead>
                      <tr>
                        <th style={{ borderTopLeftRadius: "10px" }}>
                          <input
                            type="checkbox"
                            checked={areAllItemsOfficeSelected}
                            onChange={selectAllItemsOffice}
                          />
                        </th>
                        <th style={{ borderTopRightRadius: "10px" }}>Nome</th>
                      </tr>
                    </thead>
                    <tbody>
                      {officeTeams?.map((item: any, index: number) => (
                        <tr key={index}>
                          <td>
                            <input
                              type="checkbox"
                              checked={isItemOfficeSelected(item)}
                              onChange={() => selectItemOffice(item)}
                            />
                          </td>
                          <td>{item?.name}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </>
              ) : (
                ""
              )
            ) : (
              !showSelectedItems && <>Nenhuma turma encontrada</>
            )}

            {showSelectedItems && (
              <section className="selectedItemsEditGroup">
                <SelectedItems
                  selectedItems={officeTeamsSelected}
                  titleText="Turmas selecionadas"
                />
              </section>
            )}
          </Form>
        )}
        {stepNewGroup === 3 &&
          (loading ? (
            <PageLoader />
          ) : (
            <>
              {officeTeamsSelected && officeTeamsSelected.lenght > 0 ? (
                <>Nenhum turma adicionada neste grupo.</>
              ) : (
                <Form>
                  <Form.Label column sm={12} className="mt-2">
                    Turmas que serão adicionadas a este novo grupo.
                  </Form.Label>
                  <Row>
                    <Col md={12}>
                      <TableComponent
                        data={officeTeamsSelected}
                        columns={[
                          {
                            headerName: "Nome",
                            property: "name",
                            type: "text",
                            headerClass: " border-radius-left",
                          },
                          {
                            headerName: "",
                            property: "",
                            type: "actions",
                            headerClass: "border-radius-right",
                            actions: [
                              {
                                nameIcon: "BsTrash",
                                callback: (item) => handleRemoveTeam(item),
                                className: "cursor-pointer m-1",
                                color: "#A56300",
                                size: 24,
                              },
                            ],
                          },
                        ]}
                      />
                    </Col>
                  </Row>
                </Form>
              )}
            </>
          ))}
      </Modal.Body>
      <Modal.Footer>
        {stepNewGroup === 1 && (
          <>
            <Button
              variant="primary"
              className="cancel-button"
              onClick={() => props.onHide()}
            >
              <BsX className=" mr-2" color="#A56300" size={24} />
              {isEdit ? "Cancelar" : "Descartar grupo"}
            </Button>
            <Stepper totalSteps={3} currentStep={1} />
            <Button
              variant="primary"
              className="sucess-button"
              disabled={!nameGroup || !descriptionGroup}
              onClick={() => handlerNextStep()}
            >
              Continuar
              <BsArrowRight className="ml-3" color="#Fff" size="24" />
            </Button>
          </>
        )}
        {stepNewGroup === 2 && (
          <>
            <Button
              variant="primary"
              className="cancel-button"
              onClick={() => setStepNewGroup(1)}
            >
              <BsArrowLeft color="#A56300" size="24" />
              Voltar
            </Button>
            <Stepper totalSteps={3} currentStep={2} />
            <Button
              variant="primary"
              className="sucess-button"
              disabled={officeTeamsSelected?.length <= 0 || loading}
              onClick={() => setStepNewGroup(3)}
            >
              Continuar
              <BsArrowRight className="ml-3" color="#Fff" size="24" />
            </Button>
          </>
        )}
        {stepNewGroup === 3 && (
          <>
            <Button
              variant="primary"
              className="cancel-button"
              onClick={() => setStepNewGroup(2)}
            >
              <BsArrowLeft color="#A56300" size="24" />
              Voltar
            </Button>
            <Stepper totalSteps={3} currentStep={3} />
            <Button
              variant="primary"
              className="sucess-button"
              disabled={officeTeamsSelected?.length <= 0 || loading}
              onClick={() => handleSaveNewGroup()}
            >
              {isEdit ? "Editar Grupo" : "Criar grupo"}
              <BsCheck2 color="#Fff" size="24" />
            </Button>
          </>
        )}
      </Modal.Footer>
    </ModalNewGroupStyles>
  );
}

const ModalNewGroupStyles = styled(Modal)`
  .clear-filter {
    width: 48px;
    height: 48px;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #a56300;
    background-color: #fff !important;
    border: 1px solid #a56300 !important;
    box-sizing: border-box;
    border-radius: 8px;
    &:focus {
      background: none !important;
      border: 1px solid #a56300 !important;
      color: #a56300 !important;
      box-shadow: none !important;
    }
    &:active {
      background: none !important;
      color: #a56300 !important;
    }
    &:hover {
      background: #a56300 !important;
      color: #fff !important;
      border: 1px solid #fff !important;
      .bi-x-circle {
        fill: #fff !important;
      }
      svg {
        color: #fff !important;
      }
    }
    &:disabled {
      background: none !important;
      border: #a56300 !important;
    }
  }
  .checkbox-teams {
    width: 20px;
    height: 20px;
  }
  .modal-dialog {
    margin: 0px auto;
    overflow-y: initial !important;
    height: 100% !important;
  }
  .modal-content {
    width: 800px;
    height: 100%;
  }

  .modal-body {
    overflow-y: auto !important;
    overflow-x: clip !important;
    max-height: calc(100vh-100px);
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    overflow-y: auto;
    overflow-x: clip;
    margin-left: 25px;
    margin-right: 25px;
    margin-bottom: 0px;
    padding: 0px;
  }
  .form-control {
    padding: 2px 16px 0px;
    width: 100%;
    height: 48px;
    left: 24px;
    top: 104px;
    background: #ffffff;
    border: 1px solid #a6a6a6;
    box-sizing: border-box;
  }
  .form-select-type {
    padding: 10px;
    width: 100%;
    height: 48px;
    left: 24px;
    top: 104px;
    background: #ffffff;
    border: 1px solid #a6a6a6;
    box-sizing: border-box;
  }
  h2 {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #6f6f6f;
  }
  .modal-footer {
    place-content: space-between;
    border-top: none;

    button {
      display: flex !important;
      gap: 8px !important;
      align-items: center !important;
      justify-content: center !important;

      & svg {
        margin: 0 !important;
      }
    }

    .cancel-button {
      width: 196px;
      height: 48px;
      color: #a56300;
      background-color: #fff !important;
      border: 1px solid #a56300 !important;
      box-sizing: border-box;
      border-radius: 8px;
      &:hover {
        color: #fff !important;
        background-color: #a56300 !important;

        svg {
          fill: #fff !important;
        }
      }
      &:active {
        color: #fff !important;
        background-color: #a56300 !important;
        svg {
          fill: #fff !important;
        }
      }
      &:focus {
        border: none !important;
      }
    }
    .sucess-button {
      width: 190px;
      height: 48px;
      background: #008555;
      border: 1px solid #008555;
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
      border-radius: 8px;
      &:hover {
        color: #fff !important;
        background-color: #153d89 !important;
        border: 1px solid #153d89;
        .icon-close {
          fill: #fff !important;
        }
      }
      &:active {
        color: #fff !important;
        background-color: #153d89 !important;
        .icon-close {
          fill: #fff !important;
        }
      }
      &:focus {
        border: none !important;
      }
    }
  }

  #button-select-all-group {
    display: none;
  }

  .selectedItemsEditGroup {
    .SIBadgesContainer {
      max-height: 50vh;
    }
  }

  .TableComponentStyles {
    margin-top: 1rem;

    input[type="checkbox"] {
      width: 20px;
      height: 20px;
    }

    thead {
      color: #424040;
      background-color: #e4e3e3;
      tr {
        text-align-last: center;
        height: 50px;
      }
    }

    tbody {
      tr {
        text-align-last: center;
        height: 50px;
      }
    }
  }
`;
