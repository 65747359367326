import React from "react";
import SelectedItems from "components/SelectedItems";
import DatePicker from "react-datepicker";
import { PreviewContainer } from "./styles";

interface PreviewProps {
  selectedGroups: [];
  selectedForms: [];
  startDate: any;
  closeDate: any;
  name?: string;
}

const Preview = ({
  selectedForms,
  selectedGroups,
  startDate,
  closeDate,
  name,
}: PreviewProps) => {
  return (
    <PreviewContainer>
      {name && (
        <div className="previewItemContainer nameContainer">
          <span>
            Nome da atribuição: <strong>{name}</strong>
          </span>
        </div>
      )}

      {startDate && (
        <div className="previewItemContainer dateContainer">
          <span>Data e horário de Início</span>
          <DatePicker
            selected={startDate}
            onChange={() => ""}
            showTimeSelect
            timeFormat="HH:mm"
            timeIntervals={60}
            timeCaption="Horário"
            locale="ptBR"
            dateFormat="dd/MM/yyyy - HH:mm"
            disabled
          />
        </div>
      )}

      {closeDate && (
        <div className="previewItemContainer dateContainer">
          <span>Data e horário de Encerramento</span>
          <DatePicker
            selected={closeDate}
            onChange={() => ""}
            showTimeSelect
            timeFormat="HH:mm"
            timeIntervals={60}
            timeCaption="Horário"
            locale="ptBR"
            dateFormat="dd/MM/yyyy - HH:mm"
            disabled
          />
        </div>
      )}

      <div className="previewItemContainer">
        <SelectedItems
          selectedItems={selectedGroups}
          collectionType="group"
          titleText="Grupos selecionados"
        />
      </div>

      <div className="previewItemContainer">
        <SelectedItems
          selectedItems={selectedForms}
          collectionType="forms"
          titleText="Formulários selecionados"
        />
      </div>
    </PreviewContainer>
  );
};

export default Preview;
