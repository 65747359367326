import React from "react";
import { Table, Button } from "react-bootstrap";
import moment from "moment";
import { BsInfoCircle, BsPencil, BsTrash } from "react-icons/bs";

import { TableStylesCustom } from "./styles";

interface TableProps {
  data: any;
  handleDeleteData: Function;
  handleEditData: Function;
  handleShowData: Function;
}

const CustomTable = ({
  data,
  handleDeleteData,
  handleEditData,
  handleShowData,
}: TableProps) => {
  const handleRenderActionButtons = (startDateItem: string, item: any) => {
    const todayDate: any = moment().format();
    const startDate: any = moment(startDateItem).format();

    if (moment(todayDate).isSameOrBefore(startDate)) {
      return (
        <section className="actionButtons">
          <Button
            variant="link"
            className="action-item"
            onClick={() => handleShowData(item)}
          >
            <BsInfoCircle size={24} color="#0F7EAA" />
          </Button>
          <Button
            variant="link"
            className="action-item"
            onClick={() => handleEditData(item)}
          >
            <BsPencil size={24} color="#0F7EAA" />
          </Button>
          <Button
            variant="link"
            className="action-item"
            onClick={() => handleDeleteData(item)}
          >
            <BsTrash size={24} color="#A56300" />
          </Button>
        </section>
      );
    }

    return (
      <section className="actionButtons">
        <Button
          variant="link"
          className="action-item"
          onClick={() => handleShowData(item)}
        >
          <BsInfoCircle size={24} color="#0F7EAA" />
        </Button>
      </section>
    );
  };

  return (
    <TableStylesCustom md={12}>
      <Table className="mt-3 table-list" striped borderless hover responsive>
        <thead>
          <tr className="title-table" style={{ textAlignLast: "center" }}>
            <th className="border-radius-left">Nome da Atribuição</th>
            <th>Data e Hora de Início</th>
            <th>Data e Hora de Término</th>
            <th className="border-radius-right">Ações</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item: any) => {
            const { name, initialDate, finalDate } = item;
            return (
              <tr key={name} className="table-values">
                <td>{name}</td>
                <td>{moment(initialDate).format("DD/MM/YYYY, HH:mm:ss")}</td>
                <td>{moment(finalDate).format("DD/MM/YYYY, HH:mm:ss")}</td>
                <td>{handleRenderActionButtons(initialDate, item)}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </TableStylesCustom>
  );
};

export default CustomTable;
