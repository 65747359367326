import React, { useState } from "react";
import { Row } from "react-bootstrap";
import { ApiInterface } from "@brainz_group/webappframework";
import SelectSearch, { SelectSearchOption } from "react-select-search";
import styled from "styled-components";

export interface BrainzComboBoxProps {
  setValue: React.Dispatch<React.SetStateAction<any>>;
  value: string | string[] | undefined;
  endpoint?: string;
  idField: string;
  valueField: string;
  placeholder: string;
  options?: SelectSearchOption[];
  isInvalid?: boolean;
  search?: boolean;
  handleResultIsEmpty: Function;
}

export interface BrainzComboBoxItem {
  id: string;
  name: string;
}

export default function BrainzComboBox({
  setValue,
  value,
  endpoint,
  idField,
  valueField,
  placeholder,
  options,
  isInvalid,
  search,
  handleResultIsEmpty,
}: BrainzComboBoxProps) {
  const [memoryQuery, setMemoryQuery] = useState<string>();
  const [memoryResponse, setMemoryResponse] = useState([]);

  return (
    <ComboboxStyles>
      <SelectSearch
        autoComplete="off"
        options={options ?? []}
        debounce={50}
        disabled={memoryResponse?.length <= 0}
        getOptions={
          !options
            ? (query) => {
                if (query === memoryQuery) {
                  return new Promise((resolve) => {
                    resolve(memoryResponse);
                  });
                }
                return new Promise((resolve) => {
                  ApiInterface.get(
                    endpoint + "?page=1&pageSize=100&search=" + query
                  )
                    .then((response) => {
                      setMemoryQuery(query);
                      let result = response?.data?.result;

                      if (result && result?.length) {
                        let formattedResult = result?.map((item: any) => ({
                          value: item[idField],
                          name: item[valueField],
                        }));

                        setMemoryResponse(formattedResult);
                        resolve(formattedResult);
                        return;
                      }

                      handleResultIsEmpty();
                      setMemoryResponse([]);
                      resolve([]);
                    })
                    .catch((reject: any) => {
                      console.log(
                        "Erro ao buscar tipos de perguntas. Erro => ",
                        reject
                      );
                    });
                });
              }
            : undefined
        }
        search={search !== false}
        onChange={(e) => setValue(e)}
        placeholder={
          memoryResponse?.length <= 0 ? "Carregando..." : placeholder
        }
        value={value}
        className={
          isInvalid === true ? "select-search is-invalid" : "select-search"
        }
        renderValue={(valueProps: any, snapshot, className: string) => {
          var setObject = memoryResponse?.filter(
            (a) => a["value"] === value
          )[0];
          if (setObject !== undefined) valueProps["value"] = setObject["name"];
          return <input {...valueProps} className={className} />;
        }}
      />
    </ComboboxStyles>
  );
}

const ComboboxStyles = styled(Row)`
  padding-left: 10px;

  .select-search {
    padding: 0px;
    position: relative;
    box-sizing: border-box;
    display: inline-block;
    width: 100%;
    font-size: 1rem;
    font-weight: 400;
    color: #495057;
    vertical-align: middle;
    border: 1px solid #ced4da;
    border-radius: 8px;
    -webkit-appearance: none;
    appearance: none;
    height: 48px;
    background-color: #f5f5f5;
  }

  .select-search *,
  .select-search *::after,
  .select-search *::before {
    box-sizing: inherit;
  }

  /**
   * Value wrapper
   */
  .select-search__value {
    padding: 0px;
    position: relative;
    z-index: 1;
    box-sizing: border-box;
    display: inline-block;
    width: 100%;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    vertical-align: middle;
    border: none;
    border-radius: unset;
    -webkit-appearance: none;
    appearance: none;
    height: 100%;
  }

  .select-search__value::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: calc(50% - 9px);
    right: 19px;
    width: 11px;
    height: 11px;
  }

  /**
   * Input
   */
  .select-search__input {
    box-shadow: unset;
    outline: none;
    border: none;
    -webkit-appearance: none;
    appearance: none;

    display: flex;
    align-items: center;

    width: 100%;
    height: 100%;

    background: #fff;
    padding: 6px 12px;
    border-radius: 8px;

    font-size: 1rem;
    line-height: 20px;
    font-weight: 400;
    color: #495057;

    text-overflow: ellipsis;
    box-sizing: border-box;
  }

  .select-search__input::-webkit-search-decoration,
  .select-search__input::-webkit-search-cancel-button,
  .select-search__input::-webkit-search-results-button,
  .select-search__input::-webkit-search-results-decoration {
    -webkit-appearance: none;
  }

  .select-search__input:not([readonly]):focus {
    cursor: initial;
  }

  /**
   * Options wrapper
   */
  .select-search__select {
    background: #fff;
    box-shadow: unset;
    z-index: 2000;
    border-radius: unset !important;
    border: 1px solid #e3e3e3;
    border-top: unset;
  }

  /**
   * Options
   */
  .select-search__options {
    list-style: none;
    margin-left: -2rem;
    border-radius: 0;
  }

  /**
   * Option
   */
  .select-search__option,
  .select-search__not-found {
    display: flex;
    align-items: center;

    min-height: 46px;
    height: 100%;
    width: 100%;

    padding: 0 16px;
    border-radius: unset;
    border: none;
    outline: none;

    background: #fff;

    font-size: 1rem;
    line-height: 20px;

    cursor: pointer;
  }

  .select-search--multiple .select-search__option {
    height: 68px;
  }

  .select-search__option.is-selected {
    background: rgba(33, 85, 186, 0.8);
    color: #fff;
  }

  .select-search__option.is-highlighted,
  .select-search__option:not(.is-selected):hover {
    background: rgba(110, 144, 210, 0.1);
  }

  .select-search__option.is-highlighted.is-selected,
  .select-search__option.is-selected:hover {
    background: rgba(33, 85, 186, 0.8);
    color: #fff;
  }

  /**
   * Group
   */
  .select-search__group-header {
    font-size: 10px;
    text-transform: uppercase;
    background: #eee;
    padding: 8px 16px;
  }

  /**
   * States
   */
  .select-search.is-disabled {
    opacity: 0.5;
  }

  .select-search.is-loading .select-search__value::after {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50' height='50' viewBox='0 0 50 50'%3E%3Cpath fill='%232F2D37' d='M25,5A20.14,20.14,0,0,1,45,22.88a2.51,2.51,0,0,0,2.49,2.26h0A2.52,2.52,0,0,0,50,22.33a25.14,25.14,0,0,0-50,0,2.52,2.52,0,0,0,2.5,2.81h0A2.51,2.51,0,0,0,5,22.88,20.14,20.14,0,0,1,25,5Z'%3E%3CanimateTransform attributeName='transform' type='rotate' from='0 25 25' to='360 25 25' dur='0.6s' repeatCount='indefinite'/%3E%3C/path%3E%3C/svg%3E");
    background-size: 8px;
    background-position: center;
    margin-top: 3px;
    background-repeat: no-repeat;
  }

  .select-search:not(.is-disabled) .select-search__input {
    cursor: pointer;
  }

  /**
   * Modifiers
   */
  .select-search--multiple {
    border-radius: 3px;
    overflow: hidden;
  }

  .select-search:not(.is-loading):not(.select-search--multiple)
    .select-search__value::after {
    background-image: url('data:image/svg+xml,<svg width="70" height="70" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 44"><path d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"></path></svg>');
    background-size: 36px 36px;
    background-position: initial;
    background-repeat: no-repeat;
    cursor: pointer;
  }

  .select-search--multiple .select-search__input {
    cursor: initial;
  }

  .select-search--multiple .select-search__input {
    border-radius: 3px 3px 0 0;
  }

  .select-search--multiple:not(.select-search--search) .select-search__input {
    cursor: default;
  }

  .select-search:not(.select-search--multiple) .select-search__select {
    position: absolute;
    z-index: 2;
    top: 44px;
    right: 0;
    left: 0;
    border-radius: 0;
    overflow: auto;
    max-height: 360px;
  }

  .select-search--multiple .select-search__select {
    position: relative;
    overflow: auto;
    max-height: 260px;
    border-top: 1px solid #eee;
    border-radius: 0;
  }

  .select-search__not-found {
    height: auto;
    padding: 16px;
    text-align: center;
    color: #888;
  }
  .brainz-combobox-arrow {
    position: absolute;
    top: 18px;
    right: 30px;
    z-index: 100;
    pointer-events: none;
  }
`;
