import { Modal } from "react-bootstrap/";
import styled from "styled-components";

export const ModalNewFormStyles = styled(Modal)`
  .modal-dialog {
    margin: 0px auto;
    overflow-y: initial !important;
    height: 100% !important;
  }
  .modal-content {
    width: 800px;
    height: 100%;
  }
  .modal-body {
    overflow-y: auto !important;
    overflow-x: clip !important;
    max-height: calc(100vh-100px);
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    overflow-y: auto;
    overflow-x: clip;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 0px;
    padding: 0px;
  }
  .form-control {
    padding: 2px 16px 0px;
    width: 100%;
    height: 48px;
    left: 24px;
    top: 104px;
    background: #ffffff;
    border: 1px solid #a6a6a6;
    box-sizing: border-box;
  }
  .form-select-type {
    padding: 10px;
    width: 100%;
    height: 48px;
    left: 24px;
    top: 104px;
    background: #ffffff;
    border: 1px solid #a6a6a6;
    box-sizing: border-box;
  }
  h2 {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #6f6f6f;
  }
  .modal-footer {
    border-top: none;
    place-content: space-between;

    button {
      display: flex !important;
      gap: 8px !important;
      align-items: center !important;
      justify-content: center !important;

      & svg {
        margin: 0 !important;
      }
    }
  }

  .cancel-button {
    width: 196px;
    height: 48px;
    color: #a56300;
    background-color: #fff !important;
    border: 1px solid #a56300 !important;
    box-sizing: border-box;
    border-radius: 8px;

    &:hover {
      color: #fff !important;
      background-color: #a56300 !important;
      svg {
        fill: #fff !important;
      }
    }
    &:active {
      color: #fff !important;
      background-color: #a56300 !important;
      svg {
        fill: #fff !important;
      }
    }
    &:focus {
      border: none !important;
    }
  }
  .sucess-button {
    width: 190px;
    height: 48px;
    background: #008555;
    border: 1px solid #008555;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    &:hover {
      color: #fff !important;
      background-color: #153d89 !important;
      border: 1px solid #153d89;
      .icon-close {
        fill: #fff !important;
      }
    }
    &:active {
      color: #fff !important;
      background-color: #153d89 !important;
      .icon-close {
        fill: #fff !important;
      }
    }
    &:focus {
      border: none !important;
    }
  }
  .input-date {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 12px 8px;
    width: 142px;
    height: 48px;
    //background: #F5F5F5;
    border: 1px solid #a6a6a6;
    box-sizing: border-box;
    border-radius: 8px;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
    margin: 0px 0px;
    &:focus {
      box-shadow: none !important;
    }
  }
  .sucess-button-add-new-answer {
    color: #153d89 !important;
    width: 190px;
    height: 48px;
    background: #fff;
    border: 1px solid #153d89 !important;
    border-radius: 8px;
    &:hover {
      color: #fff !important;
      background-color: #153d89 !important;
      border: 1px solid #153d89;
      .icon-close {
        fill: #153d89 !important;
      }
    }
    &:active {
      background-color: #153d89 !important;
      .icon-close {
        fill: #153d89 !important;
      }
    }
    &:focus {
      border: none !important;
    }
  }
  .button-radio {
    margin-right: 10px;
    font-size: 16px;
    line-height: 35px;
    color: #6f6f6f;
    input {
      width: 32px !important;
      height: 32px !important;
    }
    label {
      padding-left: 20px;
      padding-top: 8px;
    }
  }

  .notFoundMessage {
    color: #7e7e7e;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 5rem 0;
    font-size: 22px;
    line-height: 40px;
    text-align: center;
  }

  .paginationSection {
    position: absolute;
    bottom: 0;
    width: 100%;
  }

  .errorMessageContainer {
    margin: 8px 0;
    padding: 8px;
  }

  .errorMessageContainer {
    color: #e12828;
    font-size: 1rem;
    line-height: 20px;
    font-weight: 600;
  }

  .checkbox-teams {
    width: 20px;
    height: 20px;
  }

  #button-select-all-forms,
  #button-select-all-groups {
    display: none;
  }

  .react-datepicker-wrapper {
    input {
      padding: 2px 16px 0px;
      width: 100%;
      height: 48px;
      left: 24px;
      top: 104px;
      background: #fff;
      border: 1px solid #a6a6a6;
      box-sizing: border-box;
      border-radius: 8px;
      cursor: pointer;
    }
  }
`;
