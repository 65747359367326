import { useEffect, useState } from "react";
import { Button, Card, Col, Form, Modal, Row } from "react-bootstrap";
import { BsX } from "react-icons/bs";
import styled from "styled-components";
import PageLoader from "components/Loader/Loader";
import { formatDateToDDMMYYYY } from "hooks/Formatter";
import { InterfaceQuestionsModel } from "interfaces/Interfaces";
import { ApiInterface } from "@brainz_group/webappframework";

interface ModalDetailsFormProps {
  show: boolean;
  onHide: () => void;
  itemId: string;
  loadPage: () => void;
}
export default function ModalDetailsForm(props: ModalDetailsFormProps) {
  const [loading, setLoading] = useState(true);
  const [itemDetails, setItemDetails] = useState<any>();
  const [newNameForm, setNewNameForm] = useState("");
  const [newDescriptionForm, setNewDescriptionForm] = useState("");
  const [identifier, setIdentifier] = useState("");
  const [questionsList, setQuestionsList] =
    useState<Array<InterfaceQuestionsModel>>();

  function loadModal() {
    setLoading(true);
    if (props.itemId) {
      ApiInterface.get(`/Evaluation/${props.itemId}/GetById`)
        .then((response: any) => {
          setNewNameForm(response.data.result.title);
          setNewDescriptionForm(response.data.result.description);
          setItemDetails(response.data.result);
          setQuestionsList(response.data.result.questions);
          setIdentifier(response.data.result.id);
          setLoading(false);
        })
        .catch((error: any) => {
          setLoading(false);
        });
    }
  }

  useEffect(() => {
    loadModal();
  }, []);

  return (
    <ModalDetailsFormStyles
      show={props.show}
      onHide={() => {
        props.onHide();
      }}
    >
      <Modal.Header>
        <Modal.Title>
          <h2>Detalhes do Formulário</h2>
        </Modal.Title>
        <Button
          className="cursor-pointer"
          variant="white"
          onClick={() => props.onHide()}
        >
          <BsX color="var(--bs-modal-color)" size={24} />
        </Button>
      </Modal.Header>

      <Modal.Body>
        {loading ? (
          <PageLoader></PageLoader>
        ) : (
          itemDetails && (
            <Form>
              <Form.Group as={Row} className=" my-0 pt-3">
                <Col sm={7}>
                  <Form.Label column sm={12} className=" my-0 py-0">
                    <strong>Nome</strong>
                  </Form.Label>
                  <Form.Label column sm={12} className=" my-0 py-0">
                    {newNameForm}
                  </Form.Label>
                </Col>
                <Col sm={4}>
                  <Form.Label column sm={12} className=" my-0 py-0">
                    <strong>Data criação</strong>
                  </Form.Label>
                  <Form.Label column sm={12} className=" my-0 py-0">
                    {formatDateToDDMMYYYY(itemDetails?.creationDate)}
                  </Form.Label>
                </Col>
              </Form.Group>
              <Form.Group as={Row} className=" my-0 pt-3">
                <Col sm={12}>
                  <Form.Label column sm={12} className=" my-0 py-0">
                    <strong>Descrição</strong>
                  </Form.Label>
                  <Form.Label column sm={12} className=" my-0 py-0">
                    {newDescriptionForm}
                  </Form.Label>
                </Col>
              </Form.Group>

              <Form.Group as={Row} className=" my-0 pt-3">
                <Col sm={12}>
                  <Form.Label column sm={12} className=" my-0 py-0">
                    <strong>Identificador</strong>
                  </Form.Label>
                  <Form.Label column sm={12} className=" my-0 py-0">
                    {identifier}
                  </Form.Label>
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="">
                {questionsList && (
                  <>
                    <Form.Label column sm={12} className="">
                      <h2>Perguntas</h2>
                    </Form.Label>
                    {questionsList.map((item, index) => {
                      return (
                        <>
                          <Row className="py-1">
                            <Col>
                              <Card className="CardPreview">
                                <Card.Title>
                                  <h2>
                                    {index + 1}
                                    {")  "}
                                    {item.description}
                                  </h2>
                                </Card.Title>
                                <Card.Body>
                                  {!item?.answers?.length && (
                                    <textarea className="textarea" disabled />
                                  )}
                                  {item.answers &&
                                    item.answers.map((x) => {
                                      return (
                                        <>
                                          <Row>
                                            <Col md={1}>
                                              <input
                                                disabled
                                                type={
                                                  item?.questionType ===
                                                  "unique"
                                                    ? "radio"
                                                    : "checkbox"
                                                }
                                                className="checkbox"
                                              />
                                            </Col>
                                            <Col md={10}>{x.description}</Col>
                                          </Row>
                                        </>
                                      );
                                    })}
                                  <Col md={12} style={{ textAlign: "right" }}>
                                    {item.mandatory ? (
                                      <strong>
                                        <h2>*Obrigatório</h2>
                                      </strong>
                                    ) : (
                                      ""
                                    )}
                                  </Col>
                                </Card.Body>
                              </Card>
                            </Col>
                          </Row>
                        </>
                      );
                    })}
                  </>
                )}
              </Form.Group>
            </Form>
          )
        )}
      </Modal.Body>
    </ModalDetailsFormStyles>
  );
}

const ModalDetailsFormStyles = styled(Modal)`
  .CardPreview {
    background-color: #d7e4f8;
    width: 100%;
    padding: 15px 5px 5px 15px;
    .textarea {
      width: 100%;
    }
    .checkbox {
      width: 20px;
      height: 20px;
    }
  }

  .clear-filter {
    width: 48px;
    height: 48px;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #a56300;
    background-color: #fff !important;
    border: 1px solid #a56300 !important;
    box-sizing: border-box;
    border-radius: 8px;
    &:focus {
      background: none !important;
      border: 1px solid #a56300 !important;
      color: #a56300 !important;
      box-shadow: none !important;
    }
    &:active {
      background: none !important;
      color: #a56300 !important;
    }
    &:hover {
      background: #a56300 !important;
      color: #fff !important;
      border: 1px solid #fff !important;
      .bi-x-circle {
        fill: #fff !important;
      }
      svg {
        color: #fff !important;
      }
    }
    &:disabled {
      background: none !important;
      border: #a56300 !important;
    }
  }
  .checkbox-teams {
    width: 20px;
    height: 20px;
  }
  .modal-dialog {
    margin: 0px auto;
    overflow-y: initial !important;
    height: 100% !important;
  }
  .modal-content {
    width: 800px;
    height: 100%;
  }

  .modal-body {
    overflow-y: auto !important;
    overflow-x: clip !important;
    max-height: calc(100vh-100px);
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    overflow-y: auto;
    overflow-x: clip;
    margin-left: 25px;
    margin-right: 25px;
    margin-bottom: 0px;
    padding: 0px;
  }
  .form-control {
    padding: 2px 16px 0px;
    width: 100%;
    height: 48px;
    left: 24px;
    top: 104px;
    background: #ffffff;
    border: 1px solid #a6a6a6;
    box-sizing: border-box;
  }
  .form-select-type {
    padding: 10px;
    width: 100%;
    height: 48px;
    left: 24px;
    top: 104px;
    background: #ffffff;
    border: 1px solid #a6a6a6;
    box-sizing: border-box;
  }
  h2 {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #6f6f6f;
  }
`;
