import  Row from 'react-bootstrap/Row';
import styled from "styled-components";

export const FooterStyles = styled(Row)`
    background: #FFF;
    border-top:1px solid #D5D0D0;
    position: relative;
    bottom: 0px;
    min-height: 50px;
    background-color: #FBFBFF;
    font-size: smaller;
    font-family: 'Lato', sans-serif;
    padding-left: 15px;
    padding-right: 15px;
    p {
        padding: 18px;
        font-size: 14px;
    }
    .footer-row{
    min-height: 50px;
  }
`;
