import styled from "styled-components"
import { Button, Col, Modal, Row } from 'react-bootstrap';

interface InformationModalProps {
    show: boolean,
    onHide: () => void,
    title: string,
    subTitle?: string,
    confirmText: string,
    confirmAction: () => void,
    principalIcon: React.ReactNode
}

export default function InformationModal(props: InformationModalProps) {
    if (props.show)
        return (
            <InformationModalStyle show={props.show} onHide={props.onHide}>
                <Modal.Body>
                    <Row className="my-4">
                        <Col>
                            {props.principalIcon}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <h3> {props.title}</h3>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <p>
                                {props.subTitle}
                            </p>

                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                        <Button className="button-active"
                            onClick={() => props.confirmAction()}
                        >
                            {props.confirmText}
                        </Button>
                </Modal.Footer>
            </InformationModalStyle>

        )
    else return <></>
}



const InformationModalStyle = styled(Modal)` 
text-align: center;
font-style: normal;

h3{
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    color: #514F4E;
}
p{
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #636060;
}
.modal-footer{
    border-top: 0px solid!important;
    justify-content: center;
}
.button-cancel{
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #FFFFFF;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px;
    width: 229px;
    height: 48px;
    background: #2155BA;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
}
.button-active{
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #fff;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    //padding: 12px;
    width: 100%;
    height: 48px;
    border: 1px solid #2155BA;
    box-sizing: border-box;
    border-radius: 8px;
    background-color: #6468C8;
    &:active{
        color: #2155BA!important;
        background: #fff!important;
    }
    &:hover{
        color: #fff!important;
        background: #2155BA!important;
        .icon-x{
            color: #fff!important;
            //background: #2155BA!important;
        }
    }
    &:focus{
        box-shadow:none!important;
    }
    &:active{
        box-shadow:none!important;
    }
}
`
