import { BrainzAuthContextProvider } from "@brainz_group/webappframework";
import React, { useState, useEffect } from "react";
import Footer from "../footer/footer";
import Header from "../sidebar/Header";

export default function LayoutDefault(props: React.PropsWithChildren<any>) {
  const [loading, setLoading] = useState(true);
  const [scrollBrowser, setScrolling] = useState({ scrolling: false });

  function handleScroll(event: any) {
    if (window.scrollY === 0) {
      setScrolling({ scrolling: false });
    } else {
      setScrolling({ scrolling: true });
    }
  }
  useEffect(() => {
    if (loading) setLoading(false);
  }, [loading]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
  }, []);

  return (
    <BrainzAuthContextProvider waitAuthentication={true}>
      <div className="App">
        <div
          className="sidebar open"
          data-scroll={scrollBrowser.scrolling ? "scrolling" : ""}
        >
          <Header />
        </div>
        <div className="sidebar-margin expanded">
          <div className="container-fixed-height  mx-3 px-3">
            {!loading && props.children}
          </div>
          <Footer />
        </div>
      </div>
    </BrainzAuthContextProvider>
  );
}
