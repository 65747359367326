/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { BsX } from "react-icons/bs";
import { AssignmentItemData } from "../../../interfaces/Interfaces";
import PageLoader from "../../../components/Loader/Loader";
import Preview from "../Preview";
import { ModalNewFormStyles } from "./styles";

interface ModalShowAssignmentProps {
  assignmentUniqueId?: string;
  showModalDetailsAssignment: boolean;
  onHide: () => void;
  ApiInterface?: any;
}

const ModalShowAssignment = ({
  assignmentUniqueId,
  showModalDetailsAssignment,
  onHide,
  ApiInterface,
}: ModalShowAssignmentProps) => {
  // States
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [data, setData] = useState<AssignmentItemData>();

  // Functions
  const handleCallServiceAPI = (type: string) => {
    if (type === "getById") {
      if (!showModalDetailsAssignment || !assignmentUniqueId) return;

      const url = `/Assignment/${assignmentUniqueId}/GetById`;

      ApiInterface.get(url)
        .then((response: any) => {
          const finalResponse = response?.data?.result;

          if (finalResponse) {
            setData(finalResponse);
          }

          setIsLoading(false);
        })
        .catch((error: any) => {
          setIsLoading(false);
          console.log(
            `Erro ao tentar buscar por id no recurso Assignment. Erro => `,
            error
          );
        });
    }
  };

  const handleHideModal = () => {
    setData({
      name: "",
      evaluations: [],
      groups: [],
      initialDate: "",
      finalDate: "",
    });
    onHide();
  };

  useEffect(() => {
    setIsLoading(true);
    handleCallServiceAPI("getById");
  }, [showModalDetailsAssignment]);

  if (!data) return <></>;

  return (
    <ModalNewFormStyles
      show={showModalDetailsAssignment}
      onHide={handleHideModal}
    >
      <Modal.Header>
        <Modal.Title>
          <h2>Detalhes da Atribuição</h2>
        </Modal.Title>
        <Button
          className="cursor-pointer"
          variant="white"
          onClick={handleHideModal}
        >
          <BsX color="var(--bs-modal-color)" size={24} />
        </Button>
      </Modal.Header>

      <Modal.Body className="mb-2">
        {isLoading ? (
          <div className="d-flex justify-content-center">
            <PageLoader />
          </div>
        ) : (
          <>
            <h2 className="mt-3 d-flex justify-content-center">{data?.name}</h2>
            <Preview
              selectedGroups={data.groups}
              selectedForms={data.evaluations}
              startDate={Date.parse(data.initialDate)}
              closeDate={Date.parse(data.finalDate)}
            />
          </>
        )}
      </Modal.Body>
    </ModalNewFormStyles>
  );
};

export default ModalShowAssignment;
