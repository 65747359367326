import React from "react";
import { Badge, CloseButton } from "react-bootstrap";
import "./styles.css";

interface SelectedItemsProps {
  selectedItems: [];
  collectionType?: string;
  titleText?: string;
  handleRemoveItem?: Function;
}

const SelectedItems = ({
  selectedItems,
  collectionType,
  titleText,
  handleRemoveItem,
}: SelectedItemsProps) => {
  const renderSelected = selectedItems?.map((item: any) => {
    if (item) {
      return (
        <Badge
          bg="primary"
          className="selectedItemsBadgeContainer"
          key={item?.id}
        >
          <span>{item?.name ?? item?.title}</span>
          {handleRemoveItem && (
            <CloseButton
              variant="white"
              onClick={(e) => {
                e.preventDefault();
                handleRemoveItem(collectionType, item?.id);
              }}
            />
          )}
        </Badge>
      );
    }

    return <></>;
  });

  if (!selectedItems?.length) {
    return <></>;
  }

  return (
    <div className="selectedItemsContainer">
      <h3>{titleText ?? ""}</h3>
      <div className="SIBadgesContainer">{renderSelected}</div>
    </div>
  );
};

export default SelectedItems;
