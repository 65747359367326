import React, { useState, useEffect, useContext } from "react";
import { Form, Row, Col, Button } from "react-bootstrap";

import {
  BsPlusCircle,
  BsCheck2Circle,
  BsXCircle,
  BsExclamationCircle,
} from "react-icons/bs";

import { ApiInterface, BrainzAuthContext } from "@brainz_group/webappframework";
import Search from "../../components/Input/Search";
import NoResults from "../../components/NotResult/NoResults";
import InformationModal from "../../components/Modals/InformationModal";
import ConfirmModal from "../../components/Modals/ConfirmModal";
import PageLoader from "../../components/Loader/Loader";
import BrainzPagination, {
  PaginationProps,
} from "../../components/BrainzPagination/BrainzPagination";

import ModalNewAssignment from "./Modals/ModalNewAssignment";
import ModalShowAssignment from "./Modals/ModalShowAssignment";
import CustomTable from "./Table";
import { AssignmentScreenStyles } from "./styles";

interface AssignmentDataProps {
  evaluationId: string;
  groupId: string;
  id: string;
  messageSended: boolean;
}

export default function AssignmentScreen() {
  // STATES
  const [searchFilter, setSearchFilter] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showModalNewAssignment, setShowModalNewAssignment] = useState(false);
  const [showModalDetailsAssignment, setShowModalDetailsAssignment] =
    useState(false);
  const [showModalSucessAssignment, setShowModalSucessAssignment] =
    useState(false);

  const [isEdit, setIsEdit] = useState(false);
  const [isCreateNew, setIsCreateNew] = useState(false);

  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);
  const [
    showModalInformationSuccessDelete,
    setShowModalInformationSuccessDelete,
  ] = useState(false);

  const [assignmentData, setAssignmentData] = useState<
    Array<AssignmentDataProps>
  >([]);
  const [pagination, setPagination] = useState<PaginationProps | undefined>();
  const [queryPaginationParams, setQueryPaginationParams] = useState(
    "?page=1&pageSize=10"
  );

  const [assignmentUniqueId, setAssignmentUniqueId] = useState<string>("");
  const [assignmentUniqueIdUpdate, setAssignmentUniqueIdUpdate] =
    useState<string>("");

  const [IDItemForDelete, setIDItemForDelete] = useState();

  // VARIABLES
  const { getDecodedToken } = useContext(BrainzAuthContext);
  const institutionId = getDecodedToken()?.institutionId;

  // FUNCTIONS
  const handleClearFilters = () => {
    setSearchFilter("");
  };

  const handleQueryFilters = (searchQuery: any) => {
    let query = "";

    if (searchQuery !== "") {
      query = query += "&search=" + searchQuery;
    }

    return query;
  };

  const handleLoadPage = () => {
    setIsLoading(true);

    const url = `/Assignment/${institutionId}/List/${queryPaginationParams}${handleQueryFilters(
      searchFilter
    )}`;

    ApiInterface.get(url)
      .then((response: any) => {
        setPagination(response?.data?.paging);
        setAssignmentData(response?.data?.result);
        setIsLoading(false);
      })
      .catch((error: any) => {
        console.log(
          "Erro ao tentar buscar lista de todos os Assignments. Erro => ",
          error
        );
        setIsLoading(false);
      });
  };

  const handleDeleteData = (item: any) => {
    setIDItemForDelete(item?.id);
    setConfirmDeleteModal(true);
  };

  const handleConfirmDeleteData = () => {
    setConfirmDeleteModal(false);

    const url = `/Assignment/${IDItemForDelete}/Delete`;

    setIsLoading(true);

    ApiInterface.delete(url)
      .then((response: any) => {
        if (response?.response?.status === 200 || response?.status === 200) {
          setShowModalInformationSuccessDelete(true);
        }

        handleLoadPage();
      })
      .catch((error) => {
        console.log("Error when try to delete EvalutionGroup: ", error);
        setIsLoading(true);
      });
  };

  const handleModalNewAssignment = (isOpen: boolean) => {
    if (isOpen) {
      return setShowModalNewAssignment(true);
    }

    setAssignmentUniqueId("");
    return setShowModalNewAssignment(false);
  };

  const handleModalSuccessAssignment = (isOpen: boolean) => {
    if (isOpen) {
      return setShowModalSucessAssignment(true);
    }

    return setShowModalSucessAssignment(false);
  };

  const handleModalDetaisAssignment = (isOpen: boolean) => {
    if (isOpen) {
      return setShowModalDetailsAssignment(true);
    }

    return setShowModalDetailsAssignment(false);
  };

  const handleEditItem = (item: any) => {
    if (!item.id) {
      return handleModalNewAssignment(false);
    }
    setIsEdit(true);
    setAssignmentUniqueIdUpdate(item.id);
    handleModalNewAssignment(true);
  };

  const handleShowItem = (item: any) => {
    if (!item.id) {
      setShowModalDetailsAssignment(false);
    } else {
      setAssignmentUniqueId(item.id);
      setShowModalDetailsAssignment(true);
    }
  };

  const handleEmptyEditStates = () => {
    setIsEdit(false);
    setAssignmentUniqueId("");
  };

  const handleEmptyCreateNewStates = () => {
    setIsCreateNew(false);
  };

  useEffect(() => {
    handleLoadPage();
  }, [queryPaginationParams, searchFilter]);

  return (
    <section>
      <AssignmentScreenStyles>
        <section className="mainIntern">
          <h1>Atribuir formulário</h1>
          <p>
            Confira os dados das atribuições de grupos a formulários de
            avaliações.
          </p>
          <Row>
            <Col md={8}>
              <Form>
                <Form.Group
                  className="mb-3"
                  controlId="formSearchAssignment"
                  as={Row}
                >
                  <Col sm={searchFilter === "" ? 12 : 11} className="mr-0">
                    <Search
                      setSearchFilter={setSearchFilter}
                      searchFilter={searchFilter}
                      placeholder="Buscar por nome da atribuição..."
                    />
                  </Col>
                  <Col md={1} className="px-0 mx-0">
                    {searchFilter && (
                      <Button
                        className="clear-filter mx-1"
                        onClick={handleClearFilters}
                        title="Limpar filtro"
                      >
                        <BsXCircle
                          className="bi bi-x-circle"
                          color="#A56300"
                          size={24}
                        />
                      </Button>
                    )}
                  </Col>
                </Form.Group>
              </Form>
            </Col>
            <Col md={2}>
              <Button
                onClick={() => {
                  setIsCreateNew(true);
                  handleModalNewAssignment(true);
                }}
                className="action-button-form"
              >
                <BsPlusCircle className="mr-2" size={24} />
                Nova atribuição
              </Button>
            </Col>
          </Row>
        </section>
        {isLoading ? (
          <PageLoader />
        ) : assignmentData && assignmentData?.length > 0 ? (
          <>
            <section style={{ padding: "0px 30px" }} className="tableSection">
              <CustomTable
                data={assignmentData}
                handleDeleteData={handleDeleteData}
                handleEditData={handleEditItem}
                handleShowData={handleShowItem}
              />
            </section>
            <>
              {pagination && (
                <section className="paginationSection">
                  <BrainzPagination
                    setQueryParams={setQueryPaginationParams}
                    paging={pagination}
                  />
                </section>
              )}
            </>
          </>
        ) : (
          <div className="noResultsContainer">
            <NoResults />
          </div>
        )}
      </AssignmentScreenStyles>

      <ModalNewAssignment
        institutionId={institutionId}
        showModalNewAssignment={showModalNewAssignment}
        onHide={() => handleModalNewAssignment(false)}
        loadPage={handleLoadPage}
        successSave={() => {
          handleLoadPage();
          handleModalSuccessAssignment(true);
        }}
        assignmentUniqueIdUpdate={assignmentUniqueIdUpdate}
        isEdit={isEdit}
        isCreateNew={isCreateNew}
        clearEditState={handleEmptyEditStates}
        clearCreateNewState={handleEmptyCreateNewStates}
      />

      <ModalShowAssignment
        showModalDetailsAssignment={showModalDetailsAssignment}
        onHide={() => handleModalDetaisAssignment(false)}
        ApiInterface={ApiInterface}
        assignmentUniqueId={assignmentUniqueId}
      />

      {showModalSucessAssignment && (
        <InformationModal
          show={showModalSucessAssignment}
          onHide={() => handleModalSuccessAssignment(false)}
          title="Atribuição salva"
          subTitle="A Atribuição foi salva com sucesso."
          confirmAction={() => handleModalSuccessAssignment(false)}
          confirmText="Fechar"
          principalIcon={<BsCheck2Circle color="#008555" size={110} />}
        />
      )}

      {confirmDeleteModal && (
        <ConfirmModal
          show={confirmDeleteModal}
          onHide={() => setConfirmDeleteModal(false)}
          title="Deseja excluir esta atribuição?"
          confirmAction={handleConfirmDeleteData}
          refuseAction={() => setConfirmDeleteModal(false)}
          confirmText="Excluir atribuição"
          refuseText="Manter atribuição"
          principalIcon={<BsExclamationCircle color="#A56300" size={110} />}
        />
      )}

      {showModalInformationSuccessDelete && (
        <InformationModal
          show={showModalInformationSuccessDelete}
          onHide={() => setShowModalInformationSuccessDelete(false)}
          title="Atribuição excluída"
          subTitle="A atribuição foi excluída."
          confirmAction={() => [
            setShowModalInformationSuccessDelete(false),
            setConfirmDeleteModal(false),
          ]}
          confirmText="Fechar"
          principalIcon={<BsCheck2Circle color="#008555" size={110} />}
        />
      )}
    </section>
  );
}
