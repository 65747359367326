/* eslint-disable no-unreachable */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { TableComponent } from "@brainz_group/webappuiframework";
import { ApiInterface } from "@brainz_group/webappframework";
import { Button, Col, Form, Modal, Row, Alert } from "react-bootstrap";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ptBR from "date-fns/locale/pt-BR";
import {
  BsArrowLeft,
  BsArrowRight,
  BsX,
  BsCheck2,
  BsXCircle,
} from "react-icons/bs";

import Search from "../../../components/Input/Search";
import PageLoader from "../../../components/Loader/Loader";
import BrainzPagination, {
  PaginationProps,
} from "../../../components/BrainzPagination/BrainzPagination";

import SelectedItems from "../../../components/SelectedItems/index";
import Stepper from "../../../components/Stepper";
import {
  useValidation,
  feedBack,
  isInvalidFeedBack,
} from "../../../hooks/Validation";

import { ModalNewFormStyles } from "./styles";
import Preview from "../Preview";
import moment from "moment";

interface ModalNewAssignmentProps {
  institutionId?: any;
  showModalNewAssignment: boolean;
  onHide: () => void;
  loadPage: () => void;
  successSave: () => void;
  assignmentUniqueIdUpdate?: string;
  isEdit?: boolean;
  isCreateNew?: boolean;
  clearEditState: Function;
  clearCreateNewState: Function;
}

export default function ModalNewAssignment({
  institutionId,
  showModalNewAssignment,
  onHide,
  loadPage,
  successSave,
  assignmentUniqueIdUpdate,
  isEdit,
  isCreateNew,
  clearEditState,
  clearCreateNewState,
}: ModalNewAssignmentProps) {
  if (!isEdit && !isCreateNew) return <></>;

  if (isEdit && !assignmentUniqueIdUpdate) return <></>;

  registerLocale("ptBR", ptBR);
  // States
  const [stepNewAssignment, setStepNewAssignment] = useState(1);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [startDateTime, setStartDateTime] = useState<Date>(
    new Date(Date.now())
  );
  const [closeDateTime, setCloseDateTime] = useState<Date>(
    new Date(Date.now() + 300000000)
  );

  const [errorCloseDateMsg, setErrorCloseDateMsg] = useState<string>("");

  const [searchFilterGroup, setSearchFilterGroup] = useState<string>("");
  const [searchFilterForm, setSearchFilterForm] = useState<string>("");

  const [dataGroup, setDataGroup] = useState<any>([]);
  const [dataForm, setDataForm] = useState<any>([]);

  const [selectedGroups, setSelectedGroups] = useState<any>([]);
  const [selectedForms, setSelectedForms] = useState<any>([]);

  const [assignmentName, setAssignmentName] = useState<string>("");
  const [errorOnSaveMsg, setErrorOnSaveMsg] = useState<string>("");

  // Pagination
  const [paginationGroup, setPaginationGroup] = useState<
    PaginationProps | undefined
  >();
  const [paginationForm, setPaginationForm] = useState<
    PaginationProps | undefined
  >();

  const [queryPaginationParamsGroup, setQueryPaginationParamsGroup] =
    useState("?page=1&pageSize=5");

  const [queryPaginationParamsForm, setQueryPaginationParamsForm] =
    useState("?page=1&pageSize=5");

  // Variables
  const [setValidationFormField, validationErrors] = useValidation();

  // Functions
  const handleQueryFilters = (searchQuery: any) => {
    let query = "";

    if (searchQuery !== "") {
      query = query += "&search=" + searchQuery;
    }

    return query;
  };

  const handleSelectAllCheckboxes = (e: any, collectionType: string) => {
    e.preventDefault();

    if (collectionType === "group") {
      if (dataGroup?.length === selectedGroups?.length) {
        return setSelectedGroups([]);
      }

      return setSelectedGroups(
        dataGroup?.map((item: { originalData: any }) => item?.originalData)
      );
    }

    if (collectionType === "form") {
      if (dataForm?.length === selectedForms?.length) {
        return setSelectedForms([]);
      }

      return setSelectedForms(
        dataForm?.map((item: { originalData: any }) => item?.originalData)
      );
    }
  };

  const handleCallServiceAPI = (
    collectionType: string,
    callType: string,
    searchInput: any
  ) => {
    if (callType === "get") {
      if (searchInput === "") return;
      setIsLoading(true);
      let url = "";

      if (collectionType === "group") {
        url = `/Group/${institutionId}/List${queryPaginationParamsGroup}${handleQueryFilters(
          searchInput
        )}`;
      }

      if (collectionType === "form") {
        url = `/Evaluation/${institutionId}/List${queryPaginationParamsForm}${handleQueryFilters(
          searchInput
        )}`;
      }

      ApiInterface.get(url)
        .then((response) => {
          const finalResponse = response?.data?.result;

          if (finalResponse) {
            const dataFormatted = finalResponse.map((item: any) => {
              return {
                identificador: (
                  <section className="dataTableComponents">{item?.id}</section>
                ),
                title: (
                  <section className="dataTableComponents">
                    {item?.title ?? item?.name}
                  </section>
                ),

                originalData: item,
              };
            });

            if (collectionType === "group") {
              setPaginationGroup(response?.data?.paging);
              setDataGroup(dataFormatted);
            }

            if (collectionType === "form") {
              setPaginationForm(response?.data?.paging);
              setDataForm(dataFormatted);
            }
          }

          setIsLoading(false);
        })
        .catch((error: any) => {
          setIsLoading(false);
          console.log(
            `Erro ao tentar buscar por ${collectionType}. Erro => `,
            error
          );
        });

      return;
    }

    if (callType === "post") {
      const payload = {
        name: assignmentName,
        description: assignmentName,
        institutionId,
        initialDate: moment(startDateTime)?.format(),
        finalDate: moment(closeDateTime)?.format(),
        evaluationsId: selectedForms?.map((item: { id: any }) => item.id),
        groupsId: selectedGroups?.map((item: { id: any }) => item.id),
      };

      let url = `/Assignment/Insert`;

      if (isEdit) {
        url = `/Assignment/${assignmentUniqueIdUpdate}/Update`;
      }

      setIsLoading(true);
      setErrorOnSaveMsg("");

      if (isEdit) {
        ApiInterface.put(url, payload)
          .then((response) => {
            if (response.status === 200) {
              handleOnHide();
              successSave();
            } else {
              setErrorOnSaveMsg("Erro ao salvar atribuição, tente novamente.");
              setTimeout(() => {
                handleOnHide();
                loadPage();
              }, 1000);
            }
            setIsLoading(false);
          })
          .catch((error) => {
            setIsLoading(false);
            console.log("Erro ao tentar editar assignment. Erro => ", error);
          });

        return;
      }

      ApiInterface.post(url, payload)
        .then((response) => {
          if (response.status === 200) {
            handleOnHide();
            successSave();
          } else {
            setErrorOnSaveMsg("Erro ao salvar atribuição, tente novamente.");
            setTimeout(() => {
              handleOnHide();
              loadPage();
            }, 1000);
          }
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
          console.log("Erro ao tentar criar novo Assignment. Erro => ", error);
        });

      return;
    }

    if (callType === "getById") {
      const url = `/Assignment/${assignmentUniqueIdUpdate}/GetById`;

      ApiInterface.get(url)
        .then((response: any) => {
          const finalResponse = response?.data?.result;
          if (finalResponse) {
            setAssignmentName(finalResponse.name);
            setStartDateTime(new Date(finalResponse.initialDate));
            setCloseDateTime(new Date(finalResponse.finalDate));
            setSelectedGroups(finalResponse.groups);
            setSelectedForms(finalResponse.evaluations);
            setErrorCloseDateMsg("");
          }

          setIsLoading(false);
        })
        .catch((error: any) => {
          setIsLoading(false);
          console.log(
            `Erro ao tentar buscar por id no recurso Assignment. Erro => `,
            error
          );
        });
    }
  };

  const handleSearchFilter = (formType: string, searchInput: any) => {
    if (formType === "group") {
      if (searchInput === "") {
        return setDataGroup([]);
      }

      if (searchInput === searchFilterGroup) {
        return;
      }

      setSearchFilterGroup(searchInput);
      handleCallServiceAPI("group", "get", searchInput);
    }

    if (formType === "form") {
      if (searchInput === "") {
        return setDataForm([]);
      }

      if (searchInput === searchFilterForm) {
        return;
      }
      setSearchFilterForm(searchInput);
      handleCallServiceAPI("form", "get", searchInput);
    }

    return;
  };

  const handleClearFilters = (filterName: string) => {
    if (filterName === "group") {
      setDataGroup([]);
      setSelectedGroups([]);

      return setSearchFilterGroup("");
    }
    if (filterName === "form") {
      setDataForm([]);

      setSelectedForms([]);
      return setSearchFilterForm("");
    }

    if (filterName === "all") {
      setDataGroup([]);
      setSearchFilterGroup("");
      setSelectedGroups([]);

      setSearchFilterForm("");
      setDataForm([]);
      setSelectedForms([]);

      setAssignmentName("");
      setStartDateTime(new Date());
      setCloseDateTime(new Date());

      // setErrorCloseDateMsg(
      //   "Erro: A data final não pode ser menor ou igual a data inicial."
      // );
      setStepNewAssignment(1);
    }
  };

  const handleSelectCheckbox: any = (
    collectionType: string,
    item: any,
    isCheked: boolean
  ) => {
    const { originalData } = item;

    if (!originalData) return;

    if (collectionType === "group") {
      let newGroup = [...selectedGroups];

      if (isCheked) {
        newGroup.push(originalData);
      } else {
        newGroup = newGroup.filter((x) => x.id !== originalData?.id);
      }

      return setSelectedGroups(newGroup);
    }

    if (collectionType === "form") {
      let newForm = [...selectedForms];

      if (isCheked) {
        newForm.push(originalData);
      } else {
        newForm = newForm.filter((x) => x.id !== originalData?.id);
      }
      return setSelectedForms(newForm);
    }

    return;
  };

  const handleRenderSelectCheckbox = (collectionType: string, item: any) => {
    let isChecked;

    if (collectionType === "group") {
      isChecked = selectedGroups.some(
        (element: any) => element?.id === item?.originalData?.id
      );
    }

    if (collectionType === "form") {
      isChecked = selectedForms.some(
        (element: any) => element?.id === item?.originalData?.id
      );
    }

    return (
      <td>
        <input
          className="checkbox-teams"
          type="checkbox"
          checked={isChecked}
          onChange={(e) =>
            handleSelectCheckbox(collectionType, item, e.target.checked)
          }
        />
      </td>
    );
  };

  const handleRemoveItemToSelected = (collectionType: string, itemID: any) => {
    if (collectionType === "group") {
      const newGroupArray = selectedGroups?.filter(
        (el: any) => el.id !== itemID
      );

      setSelectedGroups(newGroupArray);
    }

    if (collectionType === "form") {
      const newFormArray = selectedForms?.filter((el: any) => el.id !== itemID);

      setSelectedForms(newFormArray);
    }
  };

  const handleCloseDateTime = (date: any) => {
    const convertCloseDate = parseInt((date?.getTime() / 1000).toFixed(0));
    const convertInitialDate = parseInt(
      (startDateTime?.getTime() / 1000).toFixed(0)
    );

    if (convertCloseDate <= convertInitialDate) {
      setErrorCloseDateMsg(
        "Erro: A data final não pode ser menor ou igual a data inicial."
      );
      setCloseDateTime(startDateTime);
    } else {
      setErrorCloseDateMsg("");
      setCloseDateTime(date);
    }
  };

  const handleNameOnChange = (value: string) => {
    setAssignmentName(value);
    setValidationFormField("nameGroup", value);
  };

  // Side Effects
  useEffect(() => {
    handleCallServiceAPI("group", "get", searchFilterGroup);
  }, [queryPaginationParamsGroup]);

  useEffect(() => {
    handleCallServiceAPI("form", "get", searchFilterForm);
  }, [queryPaginationParamsForm]);

  useEffect(() => {
    if (isEdit) {
      setIsLoading(true);
      handleCallServiceAPI("", "getById", "");
    }
  }, [assignmentUniqueIdUpdate]);

  const handleOnHide = () => {
    if (isEdit) {
      clearEditState();
    }

    if (isCreateNew) {
      clearCreateNewState();
    }
    handleClearFilters("all");
    onHide();
  };

  return (
    <ModalNewFormStyles show={showModalNewAssignment} onHide={handleOnHide}>
      <Modal.Header>
        <Modal.Title>
          <h2>{isEdit ? "Editar atribuição" : "Criar nova atribuição"}</h2>
        </Modal.Title>
        <Button
          className="cursor-pointer"
          variant="white"
          onClick={handleOnHide}
        >
          <BsX color="var(--bs-modal-color)" size={24} />
        </Button>
      </Modal.Header>
      <Modal.Body className="mb-2">
        {isLoading && <PageLoader />}
        {stepNewAssignment === 1 && (
          <Form>
            <Form.Group as={Row} style={{ marginBottom: "1rem" }}>
              <Form.Label column sm={12}>
                Nome da atribuição:
              </Form.Label>
              <Col sm={12}>
                <Form.Control
                  placeholder="Digite o nome da atribuição"
                  size="sm"
                  type="text"
                  value={assignmentName}
                  isInvalid={isInvalidFeedBack("nameGroup", validationErrors)}
                  onChange={(e) => {
                    handleNameOnChange(e.target.value);
                  }}
                ></Form.Control>
                <Form.Control.Feedback type="invalid">
                  {feedBack("nameGroup", validationErrors)}
                </Form.Control.Feedback>
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="my-1">
              <Form.Label column sm={12} style={{ fontSize: "18px" }}>
                Configurar datas para disparo automático
              </Form.Label>

              <Form.Label column sm={12}>
                Data e horário de início
              </Form.Label>
              <Col sm={12}>
                <DatePicker
                  selected={startDateTime}
                  onChange={(date: any) => {
                    setStartDateTime(date);
                  }}
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={60}
                  timeCaption="Horário"
                  locale="ptBR"
                  dateFormat="dd/MM/yyyy - HH:mm"
                  todayButton="Hoje"
                  showMonthDropdown
                  showYearDropdown
                  minDate={new Date()}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="my-1">
              <Form.Label column sm={12}>
                Data e horário de fim
              </Form.Label>
              <Col sm={12}>
                <DatePicker
                  selected={closeDateTime}
                  onChange={(date: any) => handleCloseDateTime(date)}
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={60}
                  minDate={startDateTime}
                  timeCaption="Horário"
                  locale="ptBR"
                  dateFormat="dd/MM/yyyy - HH:mm"
                  todayButton="Hoje"
                  showMonthDropdown
                  showYearDropdown
                />

                {errorCloseDateMsg !== "" && (
                  <div className="errorMessageContainer">
                    <span>{errorCloseDateMsg}</span>
                  </div>
                )}
              </Col>
            </Form.Group>
          </Form>
        )}
        {stepNewAssignment === 2 && (
          <Form>
            <Form.Group as={Row}>
              <Form.Label column sm={12}>
                Selecione os grupos que receberão os formulários
              </Form.Label>
              <Col sm={searchFilterGroup === "" ? 12 : 11} className="mr-0">
                <Search
                  setSearchFilter={(event) =>
                    handleSearchFilter("group", event)
                  }
                  searchFilter={searchFilterGroup}
                  placeholder="Buscar por nome de um grupo..."
                />
              </Col>
              <Col md={1} className="px-0 mx-0">
                {searchFilterGroup && (
                  <Button
                    className="clear-filter"
                    variant="link"
                    onClick={() => handleClearFilters("group")}
                    title="Limpar filtro"
                  >
                    <BsXCircle
                      className="bi bi-x-circle"
                      color="#A56300"
                      size={24}
                    />
                  </Button>
                )}
              </Col>
            </Form.Group>

            {isLoading ? (
              <PageLoader />
            ) : dataGroup?.length ? (
              <>
                <div className="tableAssignmentGroups">
                  {/* <span
                    style={{ position: "relative", top: "2.8vw", left: "1vw" }}
                  >
                    <input
                      className="checkbox-teams"
                      type="checkbox"
                      checked={dataGroup?.length === selectedGroups?.length}
                      onClick={() =>
                        document
                          ?.getElementById("button-select-all-groups")
                          ?.click()
                      }
                    />
                  </span>
                  <button
                    onClick={(e) => handleSelectAllCheckboxes(e, "group")}
                    id="button-select-all-groups"
                  ></button> */}
                  <TableComponent
                    data={dataGroup}
                    columns={[
                      {
                        headerName: "",
                        property: "",
                        type: "dynamic",
                        headerClass: "border-radius-left",
                        renderFunction: (item) =>
                          handleRenderSelectCheckbox("group", item),
                      },
                      {
                        headerName: "Nome do Grupo",
                        property: "title",
                        type: "text",
                        headerClass: "border-radius-right",
                      },
                    ]}
                  />
                </div>

                <SelectedItems
                  selectedItems={selectedGroups}
                  handleRemoveItem={handleRemoveItemToSelected}
                  collectionType="group"
                  titleText="Grupos selecionados"
                />

                <>
                  {paginationGroup && (
                    <section className="paginationSection">
                      <BrainzPagination
                        setQueryParams={setQueryPaginationParamsGroup}
                        paging={paginationGroup}
                      />
                    </section>
                  )}
                </>
              </>
            ) : selectedGroups?.length ? (
              <SelectedItems
                selectedItems={selectedGroups}
                collectionType="group"
                titleText="Grupos selecionados"
              />
            ) : (
              <div className="notFoundMessage">
                Nenhum resultado foi encontrado.
                <br />
                Faça uma busca pelo nome de um grupo.
              </div>
            )}
          </Form>
        )}
        {stepNewAssignment === 3 && (
          <Form>
            <Form.Group as={Row}>
              <Form.Label column sm={12}>
                Selecione o formulário que será atribuído a um grupo
              </Form.Label>
              <Col sm={searchFilterForm === "" ? 12 : 11} className="mr-0">
                <Search
                  setSearchFilter={(event) => handleSearchFilter("form", event)}
                  searchFilter={searchFilterForm}
                  placeholder="Buscar por nome de um formulário..."
                />
              </Col>
              <Col md={1} className="px-0 mx-0">
                {searchFilterForm && (
                  <Button
                    className="clear-filter"
                    variant="link"
                    onClick={() => handleClearFilters("form")}
                    title="Limpar filtro"
                  >
                    <BsXCircle
                      className="bi bi-x-circle"
                      color="#A56300"
                      size={24}
                    />
                  </Button>
                )}
              </Col>
            </Form.Group>

            {isLoading ? (
              <PageLoader />
            ) : dataForm?.length ? (
              <>
                <div className="tableAssignmentForms">
                  {/* <span
                    style={{ position: "relative", top: "2.8vw", left: "1vw" }}
                  >
                    <input
                      className="checkbox-teams"
                      type="checkbox"
                      checked={dataForm?.length === selectedForms?.length}
                      onClick={() =>
                        document
                          ?.getElementById("button-select-all-forms")
                          ?.click()
                      }
                    />
                  </span>
                  <button
                    onClick={(e) => handleSelectAllCheckboxes(e, "form")}
                    id="button-select-all-forms"
                  ></button> */}
                  <TableComponent
                    data={dataForm}
                    columns={[
                      {
                        headerName: "",
                        property: "",
                        type: "dynamic",
                        headerClass: "border-radius-left",
                        renderFunction: (item) =>
                          handleRenderSelectCheckbox("form", item),
                      },
                      {
                        headerName: "Nome do Formulário",
                        property: "title",
                        type: "text",
                        headerClass: "border-radius-right",
                      },
                    ]}
                  />
                </div>

                <SelectedItems
                  selectedItems={selectedForms}
                  handleRemoveItem={handleRemoveItemToSelected}
                  collectionType="form"
                  titleText="Formulários selecionados"
                />

                <>
                  {paginationForm && (
                    <section className="paginationSection">
                      <BrainzPagination
                        setQueryParams={setQueryPaginationParamsForm}
                        paging={paginationForm}
                      />
                    </section>
                  )}
                </>
              </>
            ) : selectedForms?.length ? (
              <SelectedItems
                selectedItems={selectedForms}
                collectionType="form"
                titleText="Formulários selecionados"
              />
            ) : (
              <div className="notFoundMessage">
                Nenhum resultado foi encontrado.
                <br />
                Faça uma busca pelo nome de um formulário.
              </div>
            )}
          </Form>
        )}
        {stepNewAssignment === 4 && (
          <>
            {errorOnSaveMsg !== "" && (
              <Alert variant="danger" className="mt-3">
                {errorOnSaveMsg}
              </Alert>
            )}
            <h2 className="mt-3 d-flex justify-content-center">
              Visualização de Atribuição
            </h2>
            <Preview
              name={assignmentName}
              selectedGroups={selectedGroups}
              selectedForms={selectedForms}
              startDate={startDateTime}
              closeDate={closeDateTime}
            />
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        {stepNewAssignment === 1 && (
          <>
            <Button
              variant="primary"
              className="cancel-button"
              onClick={handleOnHide}
            >
              <BsX className=" mr-2" color="#A56300" size={24} />
              {isEdit ? "Descartar alteração" : "Descartar atribuição"}
            </Button>
            <Stepper totalSteps={4} currentStep={1} />
            <Button
              variant="primary"
              className="sucess-button"
              onClick={() => {
                setStepNewAssignment(2);
              }}
              disabled={errorCloseDateMsg !== "" || assignmentName === ""}
            >
              Próximo
              <BsArrowRight className="ml-3" color="#Fff" size="24" />
            </Button>
          </>
        )}
        {stepNewAssignment === 2 && (
          <>
            {" "}
            <Button
              variant="primary"
              className="cancel-button"
              onClick={() => setStepNewAssignment(1)}
            >
              <BsArrowLeft color="#A56300" size="24" />
              Voltar
            </Button>
            <Stepper totalSteps={4} currentStep={2} />
            <Button
              variant="primary"
              className="sucess-button"
              disabled={!selectedGroups?.length}
              onClick={() => setStepNewAssignment(3)}
            >
              Continuar
              <BsArrowRight color="#Fff" size="24" className="mx-1" />
            </Button>
          </>
        )}
        {stepNewAssignment === 3 && (
          <>
            {" "}
            <Button
              variant="primary"
              className="cancel-button"
              onClick={() => setStepNewAssignment(2)}
            >
              <BsArrowLeft color="#A56300" size="24" />
              Voltar
            </Button>
            <Stepper totalSteps={4} currentStep={3} />
            <Button
              variant="primary"
              className="sucess-button"
              disabled={!selectedForms?.length}
              onClick={() => setStepNewAssignment(4)}
            >
              {" "}
              Continuar
              <BsArrowRight color="#Fff" size="24" className="mx-1" />
            </Button>
          </>
        )}
        {stepNewAssignment === 4 && (
          <>
            {" "}
            <Button
              variant="primary"
              className="cancel-button"
              onClick={() => setStepNewAssignment(3)}
            >
              <BsArrowLeft color="#A56300" size="24" />
              Voltar
            </Button>
            <Stepper totalSteps={4} currentStep={4} />
            <Button
              variant="primary"
              className="sucess-button"
              onClick={() => {
                handleCallServiceAPI("", "post", "");
              }}
            >
              {isEdit ? "Editar atribuição" : "Criar atribuição"}
              <BsCheck2 color="#Fff" size="24" />
            </Button>
          </>
        )}
      </Modal.Footer>
    </ModalNewFormStyles>
  );
}
