import React from "react";
import { Form, Row, Col, ListGroup, Button } from "react-bootstrap";
import {
  BsChevronDoubleLeft,
  BsChevronDoubleRight,
  BsChevronLeft,
  BsChevronRight,
} from "react-icons/bs";
import styled from "styled-components";

export interface BrainzPaginationProps {
  setQueryParams: React.Dispatch<React.SetStateAction<string>>;
  paging: PaginationProps;
}
export interface PaginationProps {
  currentRecord: number;
  currentPage: number;
  pageSize: number;
  recordsOnPage: number;
  totalRecords: number;
  totalPages: number;
}

function BrainzPagination(props: BrainzPaginationProps) {
  function onChange(page: number, size: number) {
    props.setQueryParams(`?page=${page}&pageSize=${size}`);
  }

  return (
    <PaginationStyles>
      <Col className="itemsPerPageContainer">
        <span>Itens por página</span>
        <Form.Group className="mx-3 mb-2 d-inline-block">
          <Form.Select
            className="page-sizes"
            value={props.paging.pageSize}
            onChange={(e) => onChange(1, +e.target.value)}
          >
            <option value={5}>5</option>
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={30}>30</option>
            <option value={40}>40</option>
            <option value={50}>50</option>
          </Form.Select>
        </Form.Group>
      </Col>
      <Col className="paginationContainer" md={"auto"}>
        {props.paging.totalPages > 1 && (
          <PaginationButtonsStyles className="mb-2">
            {/* |-------------primeira pagina----------------| */}
            {props.paging.currentPage === 1 ? (
              <Button
                disabled
                className={props.paging.currentPage !== 1 ? "disabled" : ""}
                onClick={() => onChange(1, props.paging.pageSize)}
              >
                <p>
                  <BsChevronDoubleLeft className="" size={15} />
                </p>
              </Button>
            ) : (
              <Button
                className={props.paging.currentPage !== 1 ? "" : ""}
                onClick={() => onChange(1, props.paging.pageSize)}
              >
                <p>
                  <BsChevronDoubleLeft className="" size={15} />
                </p>
              </Button>
            )}
            {/* |-------------pagina anterior----------------| */}
            {props.paging.currentPage > 1 ? (
              <Button
                className={props.paging.currentPage > 1 ? "" : ""}
                onClick={() =>
                  onChange(props.paging.currentPage - 1, props.paging.pageSize)
                }
              >
                <p>
                  <BsChevronLeft className="" size={15} />
                </p>
              </Button>
            ) : (
              <Button
                disabled
                className={props.paging.currentPage > 1 ? "disabled" : ""}
                onClick={() =>
                  onChange(props.paging.currentPage - 1, props.paging.pageSize)
                }
              >
                <p>
                  <BsChevronLeft className="" size={15} />
                </p>
              </Button>
            )}

            {props.paging.currentPage <= 3 ? (
              ""
            ) : (
              <Button
                onClick={() =>
                  onChange(props.paging.currentPage - 3, props.paging.pageSize)
                }
              >
                <p>{props.paging.currentPage - 3}</p>
              </Button>
            )}
            {props.paging.currentPage <= 2 ? (
              ""
            ) : (
              <Button
                onClick={() =>
                  onChange(props.paging.currentPage - 2, props.paging.pageSize)
                }
              >
                <p>{props.paging.currentPage - 2}</p>
              </Button>
            )}
            {props.paging.currentPage === 1 ? (
              ""
            ) : (
              <Button
                onClick={() =>
                  onChange(props.paging.currentPage - 1, props.paging.pageSize)
                }
              >
                <p>{props.paging.currentPage - 1}</p>
              </Button>
            )}
            <Button
              className="active"
              onClick={() =>
                onChange(props.paging.currentPage, props.paging.pageSize)
              }
            >
              <p>{props.paging.currentPage}</p>
            </Button>
            {props.paging.currentPage === props.paging.totalPages ? (
              ""
            ) : (
              <Button
                onClick={() =>
                  onChange(props.paging.currentPage + 1, props.paging.pageSize)
                }
              >
                <p>{props.paging.currentPage + 1}</p>
              </Button>
            )}
            {props.paging.currentPage === props.paging.totalPages ? (
              ""
            ) : props.paging.currentPage > 3 &&
              props.paging.currentPage !== 2 ? (
              ""
            ) : props.paging.currentPage === 3 &&
              props.paging.totalPages === 4 ? (
              ""
            ) : props.paging.currentPage === 2 &&
              props.paging.totalPages === 4 ? (
              ""
            ) : props.paging.currentPage === 2 &&
              props.paging.totalPages === 3 ? (
              ""
            ) : props.paging.currentPage === 1 &&
              props.paging.totalPages === 2 ? (
              ""
            ) : (
              <Button
                onClick={() =>
                  onChange(props.paging.currentPage + 2, props.paging.pageSize)
                }
              >
                <p>{props.paging.currentPage + 2}</p>
              </Button>
            )}
            {props.paging.totalPages <= 3 || props.paging.totalPages <= 4 ? (
              ""
            ) : props.paging.currentPage >= 3 ? (
              ""
            ) : (
              <Button
                onClick={() =>
                  onChange(props.paging.currentPage + 3, props.paging.pageSize)
                }
              >
                <p>{props.paging.currentPage + 3}</p>
              </Button>
            )}
            {props.paging.totalPages <= 3 || props.paging.totalPages <= 4 ? (
              ""
            ) : props.paging.currentPage !== 1 ? (
              ""
            ) : (
              <Button
                onClick={() =>
                  onChange(props.paging.currentPage + 4, props.paging.pageSize)
                }
              >
                <p>{props.paging.currentPage + 4}</p>
              </Button>
            )}

            {props.paging.currentPage === props.paging.totalPages ||
            props.paging.currentPage + 1 === props.paging.totalPages ||
            props.paging.currentPage + 2 === props.paging.totalPages ? (
              ""
            ) : (
              <Button
                disabled
                onClick={() =>
                  onChange(props.paging.currentPage + 2, props.paging.pageSize)
                }
              >
                <p>...</p>
              </Button>
            )}
            {props.paging.totalPages < 4 ? (
              ""
            ) : props.paging.currentPage === props.paging.totalPages ||
              props.paging.currentPage + 1 === props.paging.totalPages ? (
              ""
            ) : (
              <Button
                onClick={() =>
                  onChange(props.paging.totalPages, props.paging.pageSize)
                }
              >
                <p>{props.paging.totalPages}</p>
              </Button>
            )}

            {/* Button next Page */}
            {props.paging.currentPage === props.paging.totalPages ||
            props.paging.totalPages === 0 ? (
              <Button
                disabled
                className={
                  props.paging.currentPage === props.paging.totalPages ||
                  props.paging.totalPages === 0
                    ? ""
                    : ""
                }
                onClick={() =>
                  onChange(props.paging.currentPage + 1, props.paging.pageSize)
                }
              >
                <p>
                  <BsChevronRight className="" size={15} />
                </p>
              </Button>
            ) : (
              <Button
                className={
                  props.paging.currentPage === props.paging.totalPages ||
                  props.paging.totalPages === 0
                    ? ""
                    : ""
                }
                onClick={() =>
                  onChange(props.paging.currentPage + 1, props.paging.pageSize)
                }
              >
                <p>
                  <BsChevronRight className="" size={15} />
                </p>
              </Button>
            )}
            {/* Button Final Page */}
            {props.paging.currentPage === props.paging.totalPages ||
            props.paging.totalPages === 0 ? (
              <Button
                disabled
                className={
                  props.paging.currentPage === props.paging.totalPages ||
                  props.paging.totalPages === 0
                    ? ""
                    : ""
                }
                onClick={() =>
                  onChange(props.paging.totalPages, props.paging.pageSize)
                }
              >
                <p>
                  <BsChevronDoubleRight className="" size={15} />
                </p>
              </Button>
            ) : (
              <Button
                className={
                  props.paging.currentPage === props.paging.totalPages ||
                  props.paging.totalPages === 0
                    ? ""
                    : ""
                }
                onClick={() =>
                  onChange(props.paging.totalPages, props.paging.pageSize)
                }
              >
                <p>
                  <BsChevronDoubleRight className="" size={15} />
                </p>
              </Button>
            )}
          </PaginationButtonsStyles>
        )}
      </Col>
    </PaginationStyles>
  );
}

export default BrainzPagination;

const PaginationButtonsStyles = styled(Row)`
  margin: 0 auto;
  width: fit-content;

  button {
    margin: 3px;
    display: flex;
    align-items: center;
    text-align: center;
    width: 40px;
    height: 40px;

    border: 0.14vmin solid #ffffff;
    background-color: #ffffff !important;
    border-radius: 0.56vmin;
    .active {
      background: #e9effc !important;
      border: 1px solid #e4e3e3 !important;
      box-sizing: border-box;
      border-radius: 4px;
    }
    p {
      font-family: Segoe UI;
      font-style: normal;
      font-weight: 600;
      font-size: 2.22vmin;
      line-height: 2.64vmin;
      color: #2155ba !important;

      padding: 0px;
      margin: auto;
    }
    i {
      margin-bottom: 0.56vmin;
      font-size: 3.61vmin;
      color: #2155ba;
    }
    .disabled {
      background: #ffffff;
      border: none !important;
      box-sizing: border-box;
      border-radius: 0.56vmin;
      i {
        color: #b9b8b8;
      }
    }
    &:hover {
      background: #ffffff;
      border: 0.14vmin solid #2155ba !important;
      box-sizing: border-box;
      border-radius: 0.56vmin;
    }

    &:disabled {
      background: #ffffff;
      border: none !important;
      box-sizing: border-box;
      border-radius: 0.56vmin;
      i {
        color: #b9b8b8;
      }
      &:hover {
        background: #ffffff;
        border: none !important;
        box-sizing: border-box;
        border-radius: 0.56vmin;
      }
    }
    &:active {
      background-color: #e9effc !important;
      border: 0.14vmin solid #2155ba !important;
      box-sizing: border-box;
      border-radius: 0.56vmin;
      box-shadow: 0 0 0 0.28vmin #2155ba !important;
    }
    &:focus {
      background: #ffffff;
      border: 1px solid #2155ba !important;
      box-shadow: 0 0 0 0.28vmin #2155ba !important;
    }
    .active {
      background: #e9effc;
      border: 1px solid #e4e3e3;
      box-sizing: border-box;
      border-radius: 4px;
    }
  }
  button.active {
    background: #ffffff;
    border: 0.14vmin solid #6468c8 !important;
    box-shadow: none;
  }
`;
const PaginationStyles = styled(Row)`
  .paginationContainer {
    /* flex: unset !important;
    width: 100% !important; */
  }

  h3 {
    //font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: #6f6f6f;
  }
  .page-sizes {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 12px 8px;
    width: 87px;
    height: 48px;
    background: #f5f5f5;
    border: 1px solid #e4e3e3;
    box-sizing: border-box;
    border-radius: 8px !important;
  }
`;
export const StyledPagedSearchButtonList = styled(ListGroup)`
  .list-group-item {
    border: none;
    padding-left: 0.24vmin;
    padding-right: 0.24vmin;
  }
  button {
    display: flex;
    align-items: center;
    text-align: center;
    width: 6.11vmin;
    height: 6.11vmin;

    border: 0.14vmin solid #ffffff;
    background-color: #ffffff !important;
    border-radius: 0.56vmin;
    p {
      font-family: Segoe UI;
      font-style: normal;
      font-weight: 600;
      font-size: 2.22vmin;
      line-height: 2.64vmin;
      color: #6468c8 !important;
      padding: 0px;
      margin: auto;
    }
    i {
      margin-bottom: 0.56vmin;
      font-size: 3.61vmin;
      color: #6468c8;
    }
    &:hover {
      background: #ffffff;
      border: 0.14vmin solid #6468c8 !important;
      box-sizing: border-box;
      border-radius: 0.56vmin;
    }
    &:disabled {
      background: #ffffff;
      border: 0.14vmin solid #ffffff;
      i {
        color: #b9b8b8;
      }
      &:hover {
        background: #ffffff;
        border: none !important;
        box-sizing: border-box;
        border-radius: 0.56vmin;
      }
    }
    &:active {
      background-color: #ffffff !important;
      border: 0.14vmin solid #6468c8 !important;
      box-sizing: border-box;
      border-radius: 0.56vmin;
      box-shadow: 0 0 0 0.28vmin #6468c899 !important;
    }
    &:focus {
      background: #ffffff;
      border: 1px solid #6468c8 !important;
      box-shadow: 0 0 0 0.28vmin #6468c899 !important;
    }
  }
  button.active {
    background: #ffffff;
    border: 0.14vmin solid #6468c8 !important;
    box-shadow: none;
  }
`;
