import React, { useContext, useEffect, useState } from "react";
import { FormScreenStyles } from "./styles";
import { TableComponent } from "@brainz_group/webappuiframework";
import { Form, Row, Col, Button } from "react-bootstrap";
import {
  BsPlusCircle,
  BsCheck2Circle,
  BsXCircle,
  BsExclamationCircle,
} from "react-icons/bs";
import ModalNewForm from "./Modals/ModalNewForm";
import InformationModal from "components/Modals/InformationModal";
import { ApiInterface, BrainzAuthContext } from "@brainz_group/webappframework";
import PageLoader from "components/Loader/Loader";
import NoResults from "components/NotResult/NoResults";
import BrainzPagination, {
  PaginationProps,
} from "components/BrainzPagination/BrainzPagination";
import Search from "components/Input/Search";
import ConfirmModal from "components/Modals/ConfirmModal";
import ModalDetailsForm from "./Modals/ModalDetailsForm";

interface FormsModel {
  description: string;
  id: string;
  name: string;
  officeId: string;
}

export default function FormScreen() {
  const { getDecodedToken } = useContext(BrainzAuthContext);
  const institutionId = getDecodedToken()?.institutionId;

  const [forms, setForms] = useState<Array<FormsModel>>([]);
  const [loading, setLoading] = useState(true);
  const [queryPaginationParams, setQueryPaginationParams] = useState(
    "?page=1&pageSize=10"
  );
  const [paging, setPaging] = useState<PaginationProps | undefined>();
  const [searchFilter, setSearchFilter] = useState<string>("");
  const [itemId, setItemId] = useState("");
  const [idItemForDelete, setIdItemForDelete] = useState("");
  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);
  const [isEditModal, setIsEditModal] = useState(false);
  const [editItemData, setEditItemData] = useState({});

  const [showModalNewForm, setShowModalNewForm] = useState(false);

  const handleModalClose = () => {
    if (isEditModal) {
      setIsEditModal(false);
    }
    setShowModalNewForm(false);
  };
  const handleModalShow = () => setShowModalNewForm(true);

  const [showModalInformationSuccess, setShowModalInformationSuccess] =
    useState(false);
  const handleModalInformationSuccessClose = () =>
    setShowModalInformationSuccess(false);
  const handleModalInformationSuccessShow = () =>
    setShowModalInformationSuccess(true);

  const [showModalDetails, setShowModalDetails] = useState(false);
  const handleModalDetailsClose = () => setShowModalDetails(false);
  const handleModalDetailsShow = () => setShowModalDetails(true);

  const [
    showModalInformationSuccessDelete,
    setShowModalInformationSuccessDelete,
  ] = useState(false);

  const [
    showModalInformationSuccessUpdate,
    setShowModalInformationSuccessUpdate,
  ] = useState(false);
  const handleModalInformationSuccessUpdateClose = () =>
    setShowModalInformationSuccessUpdate(false);
  const handleModalInformationSuccessUpdateShow = () =>
    setShowModalInformationSuccessUpdate(true);

  const [showModalInformationErrorUpdate, setShowModalInformationErrorUpdate] =
    useState(false);
  const [showModalInformationError, setShowModalInformationError] =
    useState(false);
  const [showModalInformationErrorDelete, setShowModalInformationErrorDelete] =
    useState(false);

  const [errorMessageNewForm, setErrorMessageNewForm] = useState("");
  const [errorMessageUpdateForm, setErrorMessageUpdateForm] = useState("");

  const handleModalInformationErrorUpdate = (isOpen: boolean, msg?: string) => {
    if (msg) {
      setErrorMessageUpdateForm(msg);
    }

    if (isOpen) {
      return setShowModalInformationErrorUpdate(true);
    }

    return setShowModalInformationErrorUpdate(false);
  };

  const handleModalInformationErrorCreate = (isOpen: boolean, msg?: string) => {
    if (msg) {
      setErrorMessageNewForm(msg);
    }
    if (isOpen) {
      return setShowModalInformationError(true);
    }

    return setShowModalInformationError(false);
  };

  const handleModalInformationErrorDelete = (isOpen: boolean, msg?: string) => {
    if (msg) {
      setErrorMessageNewForm(msg);
    }
    if (isOpen) {
      return setShowModalInformationErrorDelete(true);
    }

    return setShowModalInformationErrorDelete(false);
  };

  const handleModalEditForm = (isOpen: boolean) => {
    setIsEditModal(isOpen);
  };

  function loadPage() {
    setLoading(true);
    if (queryPaginationParams) {
      ApiInterface.get(
        `/Evaluation/${institutionId}/List${queryFilters()}${queryPagination()}`
      )
        .then((response: any) => {
          setPaging(response.data.paging);
          setForms(response.data.result);
          setLoading(false);
        })
        .catch((error: any) => {
          setLoading(false);
        });
    }
  }

  useEffect(() => {
    loadPage();
  }, [queryPaginationParams, searchFilter]);

  function queryPagination() {
    let result: string;
    if (queryPaginationParams) result = `${queryPaginationParams}`;
    else result = "";
    return result;
  }
  function GoDetails(item: any, edit: boolean) {
    setLoading(true);
    setItemId(item.id);
    handleModalDetailsShow();
    setLoading(false);
  }
  function handleDelete(item: any) {
    setIdItemForDelete(item.id);
    setConfirmDeleteModal(true);
  }
  function deleteForm() {
    setConfirmDeleteModal(false);
    setLoading(true);
    ApiInterface.delete(`/Evaluation/${idItemForDelete}/Delete`)
      .then((response: any) => {
        if (response?.code === "ERR_BAD_REQUEST") {
          if (response?.response?.data?.errors) {
            handleModalInformationErrorDelete(
              true,
              response?.response?.data?.errors[0]?.message ??
                "Não é possível excluir esta avaliação."
            );
            setLoading(false);
            return;
          }
        }

        if (response.status === 200) {
          setShowModalInformationSuccessDelete(true);
          setLoading(false);
          return loadPage();
        }
      })
      .catch((error) => {
        console.error("Erro ao tentar excluir grupo. Erro => ", error);
        setLoading(false);
      });
  }

  function ClearFilters() {
    setSearchFilter("");
  }
  function queryFilters() {
    let query = "";
    if (searchFilter !== "") query = query += "?search=" + searchFilter + "&";
    else query = "";
    return query;
  }

  return (
    <section>
      <FormScreenStyles>
        <section className="mainIntern">
          <h1>Formulário de avaliação</h1>
          <p>Confira os dados dos formulários de avaliação.</p>
          <Row>
            <Col md={8}>
              <Form>
                <Form.Group
                  className="mb-3"
                  controlId="formBasicEmail"
                  as={Row}
                >
                  <Col sm={searchFilter === "" ? 12 : 11} className="mr-0">
                    <Search
                      setSearchFilter={setSearchFilter}
                      searchFilter={searchFilter}
                      placeholder="Buscar por nome ou descrição"
                    />
                  </Col>
                  <Col md={1} className="px-0 mx-0">
                    {searchFilter && (
                      <Button
                        className="clear-filter mx-1"
                        onClick={ClearFilters}
                        title="Limpar filtro"
                      >
                        <BsXCircle
                          className="bi bi-x-circle"
                          color="#A56300"
                          size={24}
                        />
                      </Button>
                    )}
                  </Col>
                </Form.Group>
              </Form>
            </Col>
            <Col md={2}>
              <Button
                className="sucess-button"
                onClick={() => handleModalShow()}
              >
                <BsPlusCircle className="mr-2" size="24" />
                Novo formulário
              </Button>
            </Col>
          </Row>
        </section>
        {loading ? (
          <PageLoader></PageLoader>
        ) : forms && forms.length > 0 ? (
          <>
            <section style={{ padding: "0px 30px" }} className="tableSection">
              <TableComponent
                data={forms}
                columns={[
                  {
                    headerName: "Nome",
                    property: "title",
                    type: "text",
                    headerClass: "border-radius-left",
                  },

                  {
                    headerName: "Descrição",
                    property: "description",
                    type: "text",
                    headerClass: "",
                  },

                  {
                    headerName: "Data de Criação",
                    property: "creationDate",
                    type: "date",
                    headerClass: "",
                  },
                  {
                    headerName: "Data de Atualização",
                    property: "updateDate",
                    type: "date",
                    headerClass: "",
                  },
                  {
                    headerName: "Ações",
                    property: "",
                    type: "actions",
                    headerClass: "border-radius-right",
                    actions: [
                      {
                        nameIcon: "BsInfoCircle",
                        callback: (item) => GoDetails(item, false),
                        className: "cursor-pointer action-item",
                        color: "#0F7EAA",
                        size: 24,
                      },
                      {
                        nameIcon: "BsPencil",
                        callback: (item) => {
                          setEditItemData({
                            id: item?.id,
                            institutionId: item?.institutionId,
                          });
                          handleModalEditForm(true);
                          handleModalShow();
                        },
                        className: "cursor-pointer action-item",
                        color: "#0F7EAA",
                        size: 24,
                      },
                      {
                        nameIcon: "BsTrash",
                        callback: (item) => handleDelete(item),
                        className: "cursor-pointer action-item",
                        color: "#A56300",
                        size: 24,
                      },
                    ],
                  },
                ]}
              />
            </section>

            {paging && (
              <section className="paginationSection">
                <BrainzPagination
                  setQueryParams={setQueryPaginationParams}
                  paging={paging}
                ></BrainzPagination>
              </section>
            )}
          </>
        ) : (
          <div className="noResultsContainer">
            <NoResults />
          </div>
        )}
      </FormScreenStyles>
      {showModalNewForm && (
        <ModalNewForm
          showModalNewForm={showModalNewForm}
          onHide={handleModalClose}
          saveSuccess={handleModalInformationSuccessShow}
          saveSuccessUpdate={handleModalInformationSuccessUpdateShow}
          saveError={(isOpen: boolean, msg: string) =>
            handleModalInformationErrorCreate(isOpen, msg)
          }
          saveErrorUpdate={(isOpen: boolean, msg: string) =>
            handleModalInformationErrorUpdate(isOpen, msg)
          }
          loadPage={loadPage}
          isEdit={isEditModal}
          itemData={editItemData}
        />
      )}
      {showModalDetails && (
        <ModalDetailsForm
          show={showModalDetails}
          onHide={handleModalDetailsClose}
          itemId={itemId}
          loadPage={loadPage}
        />
      )}

      {confirmDeleteModal && (
        <ConfirmModal
          show={confirmDeleteModal}
          onHide={() => setConfirmDeleteModal(false)}
          title="Deseja excluir este formulário?"
          confirmAction={() => deleteForm()}
          refuseAction={() => setConfirmDeleteModal(false)}
          confirmText="Excluir formulário"
          refuseText="Manter formulário"
          principalIcon={<BsExclamationCircle color="#A56300" size={110} />}
        />
      )}
      {showModalInformationSuccessDelete && (
        <InformationModal
          show={showModalInformationSuccessDelete}
          onHide={() => setShowModalInformationSuccessDelete(false)}
          title="Formulário excluído"
          subTitle="O formulário foi excluído."
          confirmAction={() => [
            setShowModalInformationSuccessDelete(false),
            setConfirmDeleteModal(false),
          ]}
          confirmText="Fechar"
          principalIcon={<BsCheck2Circle color="#008555" size={110} />}
        />
      )}

      <InformationModal
        show={showModalInformationSuccess}
        onHide={handleModalInformationSuccessClose}
        title="Cadastro concluído"
        subTitle="Novo formulário criado com sucesso."
        confirmAction={handleModalInformationSuccessClose}
        confirmText="Fechar"
        principalIcon={<BsCheck2Circle color="#008555" size={110} />}
      />

      <InformationModal
        show={showModalInformationError}
        onHide={() => handleModalInformationErrorCreate(false)}
        title="Erro ao cadastrar"
        subTitle={errorMessageNewForm}
        confirmAction={() => handleModalInformationErrorCreate(false)}
        confirmText="Tentar novamente"
        principalIcon={<BsExclamationCircle color="#A56300" size={110} />}
      />

      <InformationModal
        show={showModalInformationSuccessUpdate}
        onHide={handleModalInformationSuccessUpdateClose}
        title="Formulário atualizado"
        subTitle="Dados do formulário atualizados com sucesso."
        confirmAction={handleModalInformationSuccessUpdateClose}
        confirmText="Fechar"
        principalIcon={<BsCheck2Circle color="#008555" size={110} />}
      />
      <InformationModal
        show={showModalInformationErrorUpdate}
        onHide={() => handleModalInformationErrorUpdate(false)}
        title="Erro ao atualizar"
        subTitle={errorMessageUpdateForm}
        confirmAction={() => handleModalInformationErrorUpdate(false)}
        confirmText="Tentar novamente"
        principalIcon={<BsExclamationCircle color="#A56300" size={110} />}
      />

      <InformationModal
        show={showModalInformationErrorDelete}
        onHide={() => handleModalInformationErrorDelete(false)}
        title="Erro ao excluir"
        subTitle={errorMessageNewForm}
        confirmAction={() => handleModalInformationErrorDelete(false)}
        confirmText="Tentar novamente"
        principalIcon={<BsExclamationCircle color="#A56300" size={110} />}
      />
    </section>
  );
}
