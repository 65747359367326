import React from "react";
import { StepperContainer } from "./styles";
import { BsCheckLg } from "react-icons/bs";

interface StepperProps {
  totalSteps: any;
  currentStep: any;
}

const Stepper = ({ totalSteps, currentStep }: StepperProps) => {
  const stepsArray = [];

  for (let index = 0; index < totalSteps; index++) {
    stepsArray.push("");
  }

  const stepers1 = stepsArray?.map((__, index: any) => {
    return (
      <div
        key={index}
        className={`step ${index + 1 === currentStep ? "active" : ""} ${
          index + 1 < currentStep ? "old" : ""
        }`}
      >
        {index + 1 < currentStep ? <BsCheckLg size={10} color="#fff" /> : ""}
      </div>
    );
  });

  return <StepperContainer>{stepers1}</StepperContainer>;
};

export default Stepper;
