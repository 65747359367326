import React from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import styled from 'styled-components';
import NotResultImage from '../../assets/images/not-result-image.png';

export interface NoResultsProps {
    clearFilterFunction?: () => void
}

export default function NoResults(props: NoResultsProps) {
    return (
        <Row style={{ margin: 'auto' }}>
            <NotResultComponentStyles md={12} >
                <Row className="py-4"><Col><img src={NotResultImage} alt="Sem resultados" /></Col></Row>
                <Row><Col><h2>Não encontramos resultados para sua busca!</h2></Col></Row>
                {props.clearFilterFunction &&
                    <Row className="mx-3 " style={{ margin: 'auto' }}>
                        <Col>
                            <Button onClick={props.clearFilterFunction}>
                                <i ><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#A56300" className="mr-2 bi bi-x-circle" viewBox="0 0 16 16">
                                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                </svg></i>
                                Limpar busca
                            </Button>
                        </Col>
                    </Row>
                }
            </NotResultComponentStyles>
        </Row>
    )
}


const NotResultComponentStyles = styled(Col)`
flex: 1;
margin-inline: auto;
align-items: center;
text-align:center;          
color: #424040;
font-style: normal; 
h2{
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    text-align: center;    
}
p{
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
}
Button{
    margin:auto;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #A56300!important;
    background-color: #fff;
    border: 1px solid #A56300!important;
    box-sizing: border-box;
    border-radius: 8px;
    &:focus{
            background: none!important;
            border: 1px solid #A56300!important;
            color: #A56300!important;
        }
        &:active{
            background: none!important;
            color: #A56300!important;
        }
        &:hover {
            background: #A56300!important;
            color: #fff!important;  
            border: 1px solid #fff!important;
            .bi-x-circle{
            fill: #fff!important;  
            }
            svg{
                color: #fff!important
            }
        }
        &:disabled{
            background: none!important;
            border:#A56300!important;;
        }
}
`
