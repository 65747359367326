import React from "react";
import styled from "styled-components";

export const PreviewContainer = styled.section`
  .previewItemContainer {
    & > span {
      font-size: 20px;
      line-height: 24px;
      color: #7d7d7d;
      font-weight: 500;
      display: block;
    }

    margin-top: 1rem;
  }

  .dateContainer {
    border-top: 1px solid #ebebeb;
    padding: 0.5rem 0;

    & > span {
      margin-bottom: 16px;
    }

    .react-datepicker-wrapper {
      input {
        padding: 2px 16px 0px;
        width: 100%;
        height: 48px;
        left: 24px;
        top: 104px;
        background: #fff;
        border: 1px solid #a6a6a6;
        box-sizing: border-box;
        border-radius: 8px;

        cursor: not-allowed;
      }
    }
  }

  .nameContainer {
    border-top: 1px solid #ebebeb;
    padding: 1rem 0 0;

    margin: 2rem 0 1rem;
    span {
      font-size: 18px;
    }
  }
`;
