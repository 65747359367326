import React from "react";
import styled from "styled-components";

export const StepperContainer = styled.section`
  display: flex;
  align-items: center;
  gap: 8px;

  .step {
    border: 1px solid #7d7d7d;
    border-radius: 100px;
    width: 20px;
    height: 20px;

    &.old {
      background-color: #008555;
      border-color: #008555;

      display: flex;
      align-items: center;
      justify-content: center;
    }

    &.active {
      background-color: #0f7eaa;
      border-color: #0f7eaa;
    }
  }
`;
