import React, { Fragment, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import logoAberto from "../../assets/images/logoAberto.png";
import logoFechado from "../../assets/images/logoFechado.png";
import Cookies from "universal-cookie";
import {
  BsBoxArrowLeft,
  BsCardChecklist,
  BsChevronDoubleLeft,
  BsFileBarGraph,
  BsHouseDoor,
  BsPeople,
  BsUiChecks,
} from "react-icons/bs";

export interface BrainzInstitution {
  name: string;
  id: string;
}

export default function Header() {
  const [sidebar, setSidebar] = useState(true);

  function logout() {
    const cookie = new Cookies();
    cookie.remove(String(process.env.REACT_APP_BRAINZ_NAME_TOKEN), {
      path: "/",
      domain: process.env.REACT_APP_BRAINZ_DOMAIN,
    });
    cookie.remove("institutionId", {
      path: "/",
      domain: process.env.REACT_APP_BRAINZ_DOMAIN,
    });
    window.location.href = process.env["REACT_APP_ACCOUNT_REDIRECT"]!;
  }

  useEffect(() => {
    loadPage();
    loadSidebar();
  }, []);

  function loadPage() {
    // @ts-ignore
    document.querySelector("#btn-sidebar").addEventListener("click", () => {
      // @ts-ignore
      document.querySelector(".sidebar").classList.toggle("open");
      // @ts-ignore
      document.querySelector(".sidebar-margin").classList.toggle("expanded");

      menuBtnChange(); //calling the function(optional)
    });

    function menuBtnChange() {
      // @ts-ignore
      if (document.querySelector(".sidebar").classList.contains("open")) {
        setSidebar(true);
        // @ts-ignore
        document
          .querySelector("#btn-sidebar")
          .classList.replace("bx-menu", "bx-menu-alt-right"); //replacing the iocns class
      } else {
        setSidebar(false);
        // @ts-ignore
        document
          .querySelector("#btn-sidebar")
          .classList.replace("bx-menu-alt-right", "bx-menu"); //replacing the iocns class
      }
    }
  }

  function loadSidebar() {
    if (localStorage.getItem("sidebar") === "config") {
      clickMenuIntegra();
    }
  }
  function clickMenuIntegra() {
    // @ts-ignore
    if (
      document.querySelector(".nav-integra")?.classList?.contains("item-open")
    ) {
      // @ts-ignore
      document.querySelector(".nav-integra").classList.remove("item-open");
    } else {
      // @ts-ignore
      document.querySelector(".nav-config").classList.remove("item-open");
      // @ts-ignore
      document.querySelector(".nav-integra").classList.toggle("item-open");
    }
  }

  function clickMenuConfig() {
    // @ts-ignore
    if (document.querySelector(".nav-config").classList.contains("item-open")) {
      // @ts-ignore
      document.querySelector(".nav-config").classList.remove("item-open");
    } else {
      // @ts-ignore
      document.querySelector(".nav-integra").classList.remove("item-open");
      // @ts-ignore
      document.querySelector(".nav-config").classList.toggle("item-open");
    }
  }

  function clickVerificacaoMenu(value: any) {
    localStorage.setItem("sidebar", value);
    loadSidebar();
  }

  return (
    <Fragment>
      <div className="logo-details">
        <div className="logo_name">
          {sidebar ? (
            <img src={logoAberto} alt="" width={75 + "%"} />
          ) : (
            <img src={logoFechado} alt="" width={75 + "%"} />
          )}
        </div>
      </div>
      <ul className="nav-list">
        <li className="nav-item">
          <span className="tooltip">Início</span>
          <NavLink
            to="/"
            className={(navData) =>
              `sidemenu-link-sec link ${
                navData.isActive ? "sidemenu-active" : ""
              }`
            }
          >
            <i className="icone bx-grid-alt">
              <BsHouseDoor className="" color="#525252" size={22} />
            </i>
            <span className="links_name">Início</span>
          </NavLink>
        </li>
        <li className="nav-item">
          <span className="tooltip">Grupos</span>
          <NavLink
            to="/groups"
            className={(navData) =>
              `sidemenu-link-sec link ${
                navData.isActive ? "sidemenu-active" : ""
              }`
            }
          >
            <i className="icone bx-grid-alt">
              <BsPeople className="" color="#525252" size={22} />
            </i>
            <span className="links_name">Grupos</span>
          </NavLink>
        </li>
        <li className="nav-item">
          <span className="tooltip">Formulário / Avaliação</span>
          <NavLink
            to="/form"
            className={(navData) =>
              `sidemenu-link-sec link ${
                navData.isActive ? "sidemenu-active" : ""
              }`
            }
          >
            <i className="icone bx-grid-alt">
              <BsCardChecklist className="" color="#525252" size={22} />
            </i>
            <span className="links_name">Formulários de avaliação</span>
          </NavLink>
        </li>
        <li className="nav-item">
          <span className="tooltip">Atribuir formulário</span>
          <NavLink
            to="/assignment"
            className={(navData) =>
              `sidemenu-link-sec link ${
                navData.isActive ? "sidemenu-active" : ""
              }`
            }
          >
            <i className="icone bx-grid-alt">
              <BsUiChecks className="" color="#525252" size={22} />
            </i>
            <span className="links_name">Atribuir formulário</span>
          </NavLink>
        </li>
        <li className="nav-item">
          <span className="tooltip">Relatório</span>
          <a
            href="https://app.powerbi.com/view?r=eyJrIjoiYjkxNWE0MGUtOTQ0My00NmU4LTlhMDMtMGJlOTMwODAxNDEyIiwidCI6ImVjZjQ1NDgxLTlhODQtNDUyMS04ZmQ3LWQ5Zjc5OTRlMGJjYSIsImMiOjR9"
            target="_blank"
            rel="noreferrer"
          >
            <i className="icone bx-grid-alt">
              <BsFileBarGraph className="" color="#525252" size={22} />
            </i>
            <span className="links_name">Relatório</span>
          </a>
        </li>

        <li className="profile">
          <button id="btn-sidebar">
            <i className="icone bx-grid-alt">
              <BsChevronDoubleLeft className="" color="#8931E4" size={24} />
            </i>
            {sidebar ? <span className="links_name">Esconder Menu</span> : ""}
          </button>
          <button onClick={() => logout()} id="log-out">
            <i className="icone bx-grid-alt">
              <BsBoxArrowLeft className="" color="#525252" size={24} />
            </i>
            {sidebar ? <span className="links_name">Sair</span> : ""}
          </button>
        </li>
      </ul>
    </Fragment>
  );
}
