import {
  BrowserRouter as Router,
  Routes as Switch,
  Route,
} from "react-router-dom";
import LayoutDefault from "./components/layouts/LayoutDefault";
import FormScreen from "./screens/Form/FormScreen";
import AssignmentScreen from "./screens/Assignment/AssignmentScreen";
import GroupsScreen from "./screens/Groups/GroupsScreen";
// import NotFound from "./screens/NotFound/NotFound";

function BrainzRoutes() {
  return (
    <Router>
      <Switch>
        <Route
          key={0}
          path="/"
          element={<LayoutDefault>{/* <NotFound /> */}</LayoutDefault>}
        />
        <Route
          key={1}
          path="*"
          element={<LayoutDefault>{/* <NotFound /> */}</LayoutDefault>}
        />
        <Route
          key={2}
          path="/groups"
          element={
            <LayoutDefault>
              <GroupsScreen />
            </LayoutDefault>
          }
        />
        <Route
          key={3}
          path="/form"
          element={
            <LayoutDefault>
              <FormScreen />
            </LayoutDefault>
          }
        />
        <Route
          key={4}
          path="/assignment"
          element={
            <LayoutDefault>
              <AssignmentScreen />
            </LayoutDefault>
          }
        />
      </Switch>
    </Router>
  );
}

export default BrainzRoutes;
