import { ApiInterface } from "@brainz_group/webappframework";
import { TableComponent } from "@brainz_group/webappuiframework";
import { InterfaceGroupByIdModel } from "interfaces/Interfaces";
import { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { BsX } from "react-icons/bs";
import styled from "styled-components";
import PageLoader from "components/Loader/Loader";
import { formatDateToDDMMYYYY } from "hooks/Formatter";
import { useValidation } from "hooks/Validation";

interface ModalDetailsGroupProps {
  show: boolean;
  onHide: () => void;
  itemId: string;
  loadPage: () => void;
}
export default function ModalDetailsgroup(props: ModalDetailsGroupProps) {
  const [loading, setLoading] = useState(true);
  const [teamsGroup, setTeamsGroup] = useState<any>([]);
  const [itemDetails, setItemDetails] = useState<InterfaceGroupByIdModel>();
  const [newNameGroup, setNewNameGroup] = useState("");
  const [newDescriptionGroup, setNewDescriptionGroup] = useState("");
  const [validationForm, setValidationFormField] = useValidation();

  function loadModal() {
    setLoading(true);
    if (props.itemId) {
      ApiInterface.get(`/Group/${props.itemId}/GetById`)
        .then((response: any) => {
          setNewNameGroup(response.data.result.name);
          setNewDescriptionGroup(response.data.result.description);
          setItemDetails(response.data.result);
          setTeamsGroup(response.data.result.groupsOffice);
          setLoading(false);
          setValidationFormField("newNameGroup", response?.data?.result?.name);
          setValidationFormField(
            "newDescriptionGroup",
            response?.data?.result?.description
          );
        })
        .catch((error: any) => {
          setLoading(false);
        });
    }
  }

  useEffect(() => {
    loadModal();
  }, []);

  useEffect(() => {
    updateValidation();
    function updateValidation() {
      validationForm.current = {
        newNameGroup: newNameGroup,
        newDescriptionGroup: newDescriptionGroup,
      };
    }
  }, [validationForm]);

  return (
    <ModalDetailsGroupStyles
      show={props.show}
      onHide={() => {
        props.onHide();
      }}
    >
      <Modal.Header>
        <Modal.Title>
          <h2>Detalhes do grupo</h2>
        </Modal.Title>
        <Button
          className="cursor-pointer"
          variant="white"
          onClick={() => props.onHide()}
        >
          <BsX size={24} />
        </Button>
      </Modal.Header>

      <Modal.Body>
        {loading ? (
          <PageLoader></PageLoader>
        ) : (
          itemDetails && (
            <Form>
              <Form.Group as={Row} className=" my-0 pt-3">
                <Col sm={7}>
                  <Form.Label column sm={12} className=" my-0 py-0">
                    Nome
                  </Form.Label>
                  <Form.Label column sm={12} className=" my-0 py-0">
                    {newNameGroup}
                  </Form.Label>
                </Col>
                <Col sm={4}>
                  <Form.Label column sm={12} className=" my-0 py-0">
                    Data criação
                  </Form.Label>
                  <Form.Label column sm={12} className=" my-0 py-0">
                    {formatDateToDDMMYYYY(itemDetails.creationDate)}
                  </Form.Label>
                </Col>
              </Form.Group>
              <Form.Group as={Row} className=" my-0 pt-3">
                <Col sm={12}>
                  <Form.Label column sm={12} className=" my-0 py-0">
                    Descrição
                  </Form.Label>
                  <Form.Label column sm={12} className=" my-0 py-0">
                    {newDescriptionGroup}
                  </Form.Label>
                </Col>
              </Form.Group>
              {teamsGroup.length > 0 ? (
                <Form.Group as={Row} className=" my-0 pt-3">
                  <Col sm={12}>
                    {loading ? (
                      <PageLoader></PageLoader>
                    ) : (
                      <TableComponent
                        data={teamsGroup}
                        columns={[
                          {
                            headerName: "Turmas",
                            property: "name",
                            type: "text",
                            headerClass:
                              " border-radius-left border-radius-right",
                          },
                        ]}
                      />
                    )}
                  </Col>
                </Form.Group>
              ) : (
                <Form.Group as={Row} className=" my-0 pt-3">
                  <Col sm={12}>
                    <Form.Label column sm={12} className=" my-0 py-0">
                      <p>Nenhuma turma cadastrada neste grupo.</p>
                    </Form.Label>
                  </Col>
                </Form.Group>
              )}
            </Form>
          )
        )}
      </Modal.Body>
    </ModalDetailsGroupStyles>
  );
}

const ModalDetailsGroupStyles = styled(Modal)`
  .clear-filter {
    width: 48px;
    height: 48px;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #a56300;
    background-color: #fff !important;
    border: 1px solid #a56300 !important;
    box-sizing: border-box;
    border-radius: 8px;
    &:focus {
      background: none !important;
      border: 1px solid #a56300 !important;
      color: #a56300 !important;
      box-shadow: none !important;
    }
    &:active {
      background: none !important;
      color: #a56300 !important;
    }
    &:hover {
      background: #a56300 !important;
      color: #fff !important;
      border: 1px solid #fff !important;
      .bi-x-circle {
        fill: #fff !important;
      }
      svg {
        color: #fff !important;
      }
    }
    &:disabled {
      background: none !important;
      border: #a56300 !important;
    }
  }
  .checkbox-teams {
    width: 20px;
    height: 20px;
  }
  .modal-dialog {
    margin: 0px auto;
    overflow-y: initial !important;
    height: 100% !important;
  }
  .modal-content {
    width: 800px;
    height: 100%;
  }

  .modal-body {
    overflow-y: auto !important;
    overflow-x: clip !important;
    max-height: calc(100vh-100px);
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    overflow-y: auto;
    overflow-x: clip;
    margin-left: 25px;
    margin-right: 25px;
    margin-bottom: 0px;
    padding: 0px;
  }
  .form-control {
    padding: 2px 16px 0px;
    width: 100%;
    height: 48px;
    left: 24px;
    top: 104px;
    background: #ffffff;
    border: 1px solid #a6a6a6;
    box-sizing: border-box;
  }
  .form-select-type {
    padding: 10px;
    width: 100%;
    height: 48px;
    left: 24px;
    top: 104px;
    background: #ffffff;
    border: 1px solid #a6a6a6;
    box-sizing: border-box;
  }
  h2 {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #6f6f6f;
  }
  .modal-footer {
    place-content: space-between;
    border-top: none;

    button {
      display: flex !important;
      gap: 8px !important;
      align-items: center !important;
      justify-content: center !important;

      & svg {
        margin: 0 !important;
      }
    }

    .button-edit {
      float: right;
      height: 48px;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      margin: 0px 16px;
      color: #2155ba !important;
      background: #fff !important;
      border: 1px solid #2155ba !important;
      box-sizing: border-box;
      border-radius: 8px;
      &:active {
        color: #2155ba !important;
        background: #fff !important;
      }
      &:focus {
        box-shadow: none !important;
      }
      &:active {
        box-shadow: none !important;
      }
    }
    .button-cancel-edit {
      float: left;
      height: 48px;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      margin: 0px 16px;
      color: #a56300 !important;
      background: #fff !important;
      border: 1px solid #a56300 !important;
      box-sizing: border-box;
      border-radius: 8px;
      &:active {
        color: #a56300 !important;
        background: #fff !important;
      }
      &:focus {
        background: #fff !important;
        border: 0.14vmin solid #a56300 !important;
        color: #a56300 !important;
        box-shadow: none !important;
      }
      &:active {
        box-shadow: none !important;
      }
    }
    .cancel-button {
      width: 196px;
      height: 48px;
      color: #a56300;
      background-color: #fff !important;
      border: 1px solid #a56300 !important;
      box-sizing: border-box;
      border-radius: 8px;
      &:hover {
        color: #fff !important;
        background-color: #a56300 !important;
        .icon-close {
          fill: #fff !important;
        }
      }
      &:active {
        color: #fff !important;
        background-color: #a56300 !important;
        .icon-close {
          fill: #fff !important;
        }
      }
      &:focus {
        border: none !important;
      }
    }
    .button-save {
      width: 190px;
      height: 48px;
      background: #008555;
      border: 1px solid #008555;
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
      border-radius: 8px;
      &:hover {
        color: #fff !important;
        background-color: #153d89 !important;
        border: 1px solid #153d89;
        .icon-close {
          fill: #fff !important;
        }
      }
      &:active {
        color: #fff !important;
        background-color: #153d89 !important;
        .icon-close {
          fill: #fff !important;
        }
      }
      &:focus {
        border: none !important;
      }
    }
  }
`;
