import React from 'react';
import {TailSpin} from "react-loader-spinner";
class PageLoader extends React.Component{
    constructor(props) {
        super(props);
        this.state = {};
    }
    render(){
        return(
            <div id={"loader"} className={'loader-background'}>
                      <TailSpin
                        color="#6468C8"
                        height={100}
                        width={100}
                        wrapperStyle={{margin:"auto"}}
                    />
            </div>
        );

    }
}

export default PageLoader;