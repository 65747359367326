import styled from "styled-components";
import { Form } from "react-bootstrap";
import { useEffect, useState } from "react";

interface SearchProps {
  setSearchFilter: React.Dispatch<React.SetStateAction<string>>;
  searchFilter: string;
  placeholder?: string;
}
export default function Search(props: SearchProps) {
  const [searchFilterTimed, setSearchFilterTimed] = useState<string>("");

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      props.setSearchFilter(searchFilterTimed);
    }, 1000);
    return () => clearTimeout(delayDebounceFn);
  }, [searchFilterTimed]);

  useEffect(() => {
    setSearchFilterTimed(props.searchFilter);
  }, [props.searchFilter]);

  useEffect(() => {
    // Disable ENTER Button
    window.addEventListener("keydown", (event) => {
      if (event.which === 13) {
        return event.preventDefault();
      }
    });
  }, []);

  return (
    <SearchStyles.Control
      className="filter-name"
      value={searchFilterTimed}
      onChange={(e) => {
        setSearchFilterTimed(e.target.value);
      }}
      type="text"
      placeholder={
        props.placeholder !== "" ? props.placeholder : "Buscar por..."
      }
      size="sm"
    ></SearchStyles.Control>
  );
}

const SearchStyles = styled(Form)`
  .filter-name {
    background: #f5f5f5;
    border: 1px solid #e4e3e3;
    box-sizing: border-box;
    border-radius: 8px;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
    margin: 0px 0px;
    height: 48px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 21px;
    color: #525252;
  }
`;
