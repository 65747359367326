import React, { useState, useEffect, useContext } from "react";
import { GroupsScreenStyles } from "./styles";
import { TableComponent } from "@brainz_group/webappuiframework";
import { Form, Row, Col, Button } from "react-bootstrap";
import {
  BsPlusCircle,
  BsCheck2Circle,
  BsExclamationCircle,
  BsXCircle,
} from "react-icons/bs";
import ModalNewGroup from "./Modals/ModalNewGroup";
import InformationModal from "components/Modals/InformationModal";
import { ApiInterface, BrainzAuthContext } from "@brainz_group/webappframework";
import PageLoader from "components/Loader/Loader";
import BrainzPagination, {
  PaginationProps,
} from "components/BrainzPagination/BrainzPagination";
import ModalDetailsGroup from "./Modals/ModalDetailsGroup";
import ConfirmModal from "components/Modals/ConfirmModal";
import Search from "components/Input/Search";
import NoResults from "components/NotResult/NoResults";

interface GroupModel {
  description: string;
  id: string;
  name: string;
  officeId: string;
}
export default function GroupsScreen() {
  const { getDecodedToken } = useContext(BrainzAuthContext);
  const [group, setGroup] = useState<Array<GroupModel>>([]);
  const [loading, setLoading] = useState(true);
  const [queryPaginationParams, setQueryPaginationParams] = useState(
    "?page=1&pageSize=10"
  );
  const [paging, setPaging] = useState<PaginationProps | undefined>();
  const [itemId, setItemId] = useState("");
  const [editItem, setEditItem] = useState(false);
  const [isEditItem, setIsEditItem] = useState(false);
  const [searchFilter, setSearchFilter] = useState<string>("");

  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);
  const [idItemForDelete, setIdItemForDelete] = useState("");
  const [show, setShow] = useState(false);
  const handleModalClose = () => setShow(false);
  const handleModalShow = () => setShow(true);

  const [
    showModalInformationSuccessDelete,
    setShowModalInformationSuccessDelete,
  ] = useState(false);

  const [showModalInformationSuccess, setShowModalInformationSuccess] =
    useState(false);
  const handleModalInformationSuccessClose = () =>
    setShowModalInformationSuccess(false);
  const handleModalInformationSuccessShow = () =>
    setShowModalInformationSuccess(true);

  const [showModalInformationError, setShowModalInformationError] =
    useState(false);

  const [errorMessageNewGroup, setErrorMessageNewGroup] = useState("");
  const [errorMessageUpdateGroup, setErrorMessageUpdateGroup] = useState("");

  const handleModalInformationError = (isOpen: boolean, msg?: string) => {
    if (msg) {
      setErrorMessageNewGroup(msg);
    }

    if (isOpen) {
      return setShowModalInformationError(true);
    }

    return setShowModalInformationError(false);
  };

  const handleModalInformationErrorUpdate = (isOpen: boolean, msg?: string) => {
    if (msg) {
      setErrorMessageUpdateGroup(msg);
    }

    if (isOpen) {
      return setShowModalInformationErrorUpdate(true);
    }

    return setShowModalInformationErrorUpdate(false);
  };

  const handleModalInformationErrorDelete = (isOpen: boolean, msg?: string) => {
    if (msg) {
      setErrorMessageNewGroup(msg);
    }

    if (isOpen) {
      return setShowModalInformationErrorDelete(true);
    }

    return setShowModalInformationErrorDelete(false);
  };

  const [
    showModalInformationSuccessUpdate,
    setShowModalInformationSuccessUpdate,
  ] = useState(false);
  const handleModalInformationSuccessUpdateClose = () =>
    setShowModalInformationSuccessUpdate(false);
  const handleModalInformationSuccessUpdateShow = () =>
    setShowModalInformationSuccessUpdate(true);

  const [showModalInformationErrorUpdate, setShowModalInformationErrorUpdate] =
    useState(false);

  const [showModalInformationErrorDelete, setShowModalInformationErrorDelete] =
    useState(false);

  const [showModalDetails, setShowModalDetails] = useState(false);
  const handleModalDetailsClose = () => setShowModalDetails(false);
  const handleModalDetailsShow = () => setShowModalDetails(true);

  useEffect(() => {
    loadPage();
  }, [searchFilter, queryPaginationParams]);

  function loadPage() {
    setLoading(true);
    const institutionId = getDecodedToken()?.institutionId;
    //const institutionId = "204AF39C-8D60-49B2-80C2-6DD4058C062D";
    if (queryPaginationParams)
      ApiInterface.get(
        `/Group/${institutionId}/List${queryFilters()}${queryPagination()}`
      )
        .then((response: any) => {
          setPaging(response.data.paging);
          setGroup(response.data.result);
          setLoading(false);
        })
        .catch((error: any) => {
          setLoading(false);
        });
  }

  function queryPagination() {
    let result: string;
    if (queryPaginationParams) result = `${queryPaginationParams}`;
    else result = "";
    return result;
  }

  const GoDetails = (item: any, edit: boolean) => {
    setLoading(true);
    setItemId(item.id);
    setEditItem(edit);
    handleModalDetailsShow();
    setLoading(false);
  };

  function handleDelete(item: any) {
    setIdItemForDelete(item.id);
    setConfirmDeleteModal(true);
  }
  function deleteGroup() {
    setConfirmDeleteModal(false);
    setLoading(true);
    ApiInterface.delete(`/Group/${idItemForDelete}/Delete`)
      .then((response: any) => {
        if (response?.code === "ERR_BAD_REQUEST") {
          if (response?.response?.data?.errors) {
            handleModalInformationErrorDelete(
              true,
              response?.response?.data?.errors[0]?.message ??
                "Não é possível excluir este grupo."
            );
            setLoading(false);
            return;
          }
        }

        if (response.status === 200) {
          setShowModalInformationSuccessDelete(true);
          setLoading(false);
          return loadPage();
        }
      })
      .catch((error) => {
        console.error("Erro ao tentar excluir grupo. Erro => ", error);
        setLoading(false);
      });
  }

  function ClearFilters() {
    setSearchFilter("");
  }
  function queryFilters() {
    let query = "";
    if (searchFilter !== "") query = query += "?search=" + searchFilter + "&";
    else query = "";
    return query;
  }

  return (
    <section>
      <GroupsScreenStyles>
        <section className="mainIntern">
          <h1>Grupos</h1>
          <p>Confira dados dos grupos cadastrados.</p>
          <Row>
            <Col md={8}>
              <Form>
                <Form.Group
                  className="mb-3"
                  controlId="formBasicEmail"
                  as={Row}
                >
                  <Col sm={searchFilter === "" ? 12 : 11} className="mr-0">
                    <Search
                      setSearchFilter={setSearchFilter}
                      searchFilter={searchFilter}
                      placeholder="Buscar por nome ou descrição"
                    />
                  </Col>
                  <Col md={1} className="px-0 mx-0">
                    {searchFilter && (
                      <Button
                        className="clear-filter mx-1"
                        onClick={ClearFilters}
                        title="Limpar filtro"
                      >
                        <BsXCircle
                          className="bi bi-x-circle"
                          color="#A56300"
                          size={24}
                        />
                      </Button>
                    )}
                  </Col>
                </Form.Group>
              </Form>
            </Col>
            <Col md={2}>
              <Button
                className="sucess-button"
                onClick={() => {
                  setIsEditItem(false);
                  handleModalShow();
                }}
              >
                <BsPlusCircle className="mr-2" size="24" />
                Criar grupo
              </Button>
            </Col>
          </Row>
        </section>
        {loading ? (
          <PageLoader></PageLoader>
        ) : group && group.length > 0 ? (
          <>
            <section style={{ padding: "0px 30px" }} className="tableSection">
              <TableComponent
                data={group}
                columns={[
                  {
                    headerName: "Nome",
                    property: "name",
                    type: "text",
                    headerClass: "border-radius-left",
                  },
                  {
                    headerName: "Descrição",
                    property: "description",
                    type: "text",
                    headerClass: "",
                  },
                  {
                    headerName: "Data de Criação",
                    property: "creationDate",
                    type: "date",
                    headerClass: "",
                  },
                  {
                    headerName: "Ações",
                    property: "",
                    type: "actions",
                    headerClass: "border-radius-right",
                    actions: [
                      {
                        nameIcon: "BsInfoCircle",
                        callback: (item) => GoDetails(item, false),
                        className: "cursor-pointer action-item",
                        color: "#0F7EAA",
                        size: 24,
                      },
                      {
                        nameIcon: "BsPencil",
                        callback: (item) => {
                          setItemId(item.id);
                          setIsEditItem(true);
                          handleModalShow();
                        },
                        className: "cursor-pointer action-item",
                        color: "#0F7EAA",
                        size: 24,
                      },
                      {
                        nameIcon: "BsTrash",
                        callback: (item) => handleDelete(item),
                        className: "cursor-pointer action-item",
                        color: "#A56300",
                        size: 24,
                      },
                    ],
                  },
                ]}
              />
            </section>

            <section className="paginationSection">
              {paging && (
                <BrainzPagination
                  setQueryParams={setQueryPaginationParams}
                  paging={paging}
                ></BrainzPagination>
              )}
            </section>
          </>
        ) : (
          <div className="noResultsContainer">
            <NoResults />
          </div>
        )}
        {show && (
          <ModalNewGroup
            show={show}
            onHide={handleModalClose}
            saveSuccess={handleModalInformationSuccessShow}
            saveSuccessUpdate={handleModalInformationSuccessUpdateShow}
            saveError={(isOpen: boolean, msg: string) =>
              handleModalInformationError(isOpen, msg)
            }
            loadPage={loadPage}
            isEdit={isEditItem}
            itemId={itemId}
          />
        )}
        {showModalDetails && (
          <ModalDetailsGroup
            show={showModalDetails}
            onHide={handleModalDetailsClose}
            itemId={itemId}
            loadPage={loadPage}
          />
        )}
        <InformationModal
          show={showModalInformationSuccessUpdate}
          onHide={handleModalInformationSuccessUpdateClose}
          title="Grupo Atualizado"
          subTitle="Dados do grupo atualizados com sucesso."
          confirmAction={handleModalInformationSuccessUpdateClose}
          confirmText="Fechar"
          principalIcon={<BsCheck2Circle color="#008555" size={110} />}
        />
        <InformationModal
          show={showModalInformationErrorUpdate}
          onHide={() => handleModalInformationErrorUpdate(false)}
          title="Erro ao atualizar"
          subTitle={`Descrição do erro: ${errorMessageUpdateGroup}`}
          confirmAction={() => handleModalInformationErrorUpdate(false)}
          confirmText="Tentar novamente"
          principalIcon={<BsExclamationCircle color="#A56300" size={110} />}
        />

        {confirmDeleteModal && (
          <ConfirmModal
            show={confirmDeleteModal}
            onHide={() => setConfirmDeleteModal(false)}
            title="Deseja excluir este grupo?"
            confirmAction={() => deleteGroup()}
            refuseAction={() => setConfirmDeleteModal(false)}
            confirmText="Excluir grupo"
            refuseText="Manter grupo"
            principalIcon={<BsExclamationCircle color="#A56300" size={110} />}
          />
        )}

        <InformationModal
          show={showModalInformationSuccess}
          onHide={handleModalInformationSuccessClose}
          title="Cadastro concluído"
          subTitle="Novo grupo criado com sucesso."
          confirmAction={handleModalInformationSuccessClose}
          confirmText="Fechar"
          principalIcon={<BsCheck2Circle color="#008555" size={110} />}
        />

        <InformationModal
          show={showModalInformationError}
          onHide={() => handleModalInformationError(false)}
          title="Erro ao cadastrar"
          subTitle={`Descrição do erro: ${errorMessageNewGroup}`}
          confirmAction={() => handleModalInformationError(false)}
          confirmText="Tentar novamente"
          principalIcon={<BsExclamationCircle color="#A56300" size={110} />}
        />

        {showModalInformationSuccessDelete && (
          <InformationModal
            show={showModalInformationSuccessDelete}
            onHide={() => setShowModalInformationSuccessDelete(false)}
            title="Grupo excluído"
            subTitle="O grupo foi excluído."
            confirmAction={() => [
              setShowModalInformationSuccessDelete(false),
              setConfirmDeleteModal(false),
            ]}
            confirmText="Fechar"
            principalIcon={<BsCheck2Circle color="#008555" size={110} />}
          />
        )}

        <InformationModal
          show={showModalInformationErrorDelete}
          onHide={() => handleModalInformationErrorDelete(false)}
          title="Erro ao excluir"
          subTitle={errorMessageNewGroup}
          confirmAction={() => handleModalInformationErrorDelete(false)}
          confirmText="Tentar novamente"
          principalIcon={<BsExclamationCircle color="#A56300" size={110} />}
        />
      </GroupsScreenStyles>
    </section>
  );
}
