import { Col } from "react-bootstrap";
import styled from "styled-components";

export const TableStylesCustom = styled(Col)`
  padding-right: 0px;

  .border-radius-left {
    border-top-left-radius: 10px;
  }
  .border-radius-right {
    border-top-right-radius: 10px;
  }
  .title-table {
    height: 44px;
  }
  .table-values {
    height: 56px;
  }
  th {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #424040;
    background-color: #e4e3e3;
  }
  td {
    text-align: center;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #525252;
  }
  .bg-error-color {
    background-color: #fdf1de;
    color: #a56300;
  }
  .success-status {
    background-color: #e5f4ef;
  }
  .error-status {
    background-color: #fdf1de;
  }
  .line-selected {
    background-color: #e5f4ef;
  }

  .table-list {
    td {
      padding: 10px;
      vertical-align: middle;
    }
  }

  .actionButtons {
    .action-item {
      outline: unset;
      box-shadow: unset;
      padding: 0;
    }
  }
`;
